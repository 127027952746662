
  import { Server, Response } from 'miragejs'
  import { adminData, activities, adminAndPermissionsData } from '../data/adminData'
  
  export default function adminFakeApi(server: Server, apiPrefix: string) {
  
    server.get(`${apiPrefix}/dashboard`, (schema, request) => {
      return new Response(200, {}, schema.db.adminData[0].data)
    })
  
    server.get(`${apiPrefix}/admin/activityLogs`, (schema, request) => {
      return new Response(200, {}, schema.db.activities[0])
    })

    server.get(`${apiPrefix}/donations/:startDate/:endDate/graph`, (schema, request) => {
      return new Response(200, {}, adminData['donations_graph'])
    })
  
    server.get(`${apiPrefix}/commitments/:startDate/:endDate/graph`, (schema, request) => {
      return new Response(200, {}, adminData['commitments_graph'])
    })
  
    server.get(`${apiPrefix}/admin/reload_stats`, (schema, request) => {
      return new Response(200, {}, adminData['reload'])
    })
  
    // server.get(`${apiPrefix}/client/{data.client_id}/payment-status`, (schema, request) => {
    //   return new Response(200, {}, adminData['client/*/payment-status'])
    // })
  
    server.get(`${apiPrefix}/admins`, (schema, request) => {
      const admins = schema.db.administrators
      const result = {
        admins: admins
      }
      return new Response(200, {}, result)
    })
  
    server.get(`${apiPrefix}/client/admin/{data.client_id}`, (schema, request) => {
      // TODO: Handle dynamic parameters from request.params
      return new Response(200, {}, adminData['client/admin/*'])
    })
  
    server.post(`${apiPrefix}/client/admin/{data.client_id}`, (schema, request) => {
      // TODO: Handle dynamic parameters from request.params
      return new Response(200, {}, adminData['client/admin/*'])
    })
  
    server.get(`${apiPrefix}/client/admins/:id`, (schema, request) => {
      const id = request.params.id
      const admins = schema.db.administrators
      const admin = admins.find(id)
      
      const result = {
        admin: admin,
        groups: {
          "301": "HYS Demo",
          "1472": "Uganda Permissions",
          "1473": "Congo Permissions",
          "1474": "India Permissions"
        }
      }

      return new Response(200, {}, result)
    })
  
    server.put(`${apiPrefix}/client/admins/:id`, (schema, request) => {
      return new Response(200, {}, true)
    })
  
    server.delete(`${apiPrefix}/client/admins/:admin_id`, (schema, request) => {
      const admin_id = request.params.admin_id
      schema.db.administrators.remove({id: admin_id})
      return new Response(200, {}, true)
    })  
  
    server.post(`${apiPrefix}/admin/groups`, (schema, request) => {
      return new Response(200, {}, schema.db.adminAndPermissionsData[0]['groups'])
    })
  
    server.get(`${apiPrefix}/admin/groups/:group_id`, (schema, request) => {
      const group_id = request.params.group_id
      const group = schema.db.groups.find(group_id)
      const result = {
        data: {
          all_groups: schema.db.groups,
          donors: [
            {
                "id": 639,
                "client_id": 207,
                "default_emailset_id": 337,
                "type": "donor",
                "name": "Sponsor Profiles",
                "prefix": "",
                "counter": 253,
                "box_folder_id": "",
                "can_donor_modify_amount": "",
                "mailchimp_list_id": "",
                "forgive_missed_payments": 0,
                "notify": null,
                "deleted_at": null,
                "created_at": "2017-02-23T01:40:21.000000Z",
                "updated_at": "2024-08-06T10:18:45.000000Z",
                "hide_payment": "",
                "__hevo__database_name": "apphys_new",
                "__hevo__ingested_at": 1680093778944,
                "__hevo__marked_deleted": 0,
                "__hevo__source_modified_at": 1680093318000
            },
            {
                "id": 3030,
                "client_id": 207,
                "default_emailset_id": 0,
                "type": "donor",
                "name": "new test form",
                "prefix": "",
                "counter": 0,
                "box_folder_id": "",
                "can_donor_modify_amount": "",
                "mailchimp_list_id": "",
                "forgive_missed_payments": 0,
                "notify": null,
                "deleted_at": null,
                "created_at": "2023-04-04T08:39:19.000000Z",
                "updated_at": "2023-04-04T08:39:19.000000Z",
                "hide_payment": "",
                "__hevo__database_name": null,
                "__hevo__ingested_at": null,
                "__hevo__marked_deleted": null,
                "__hevo__source_modified_at": null
            },
            {
                "id": 3040,
                "client_id": 207,
                "default_emailset_id": 0,
                "type": "donor",
                "name": "Kupenda Donors",
                "prefix": "",
                "counter": 0,
                "box_folder_id": "",
                "can_donor_modify_amount": "",
                "mailchimp_list_id": "",
                "forgive_missed_payments": 0,
                "notify": null,
                "deleted_at": null,
                "created_at": "2023-05-09T14:53:42.000000Z",
                "updated_at": "2023-05-09T14:53:42.000000Z",
                "hide_payment": "",
                "__hevo__database_name": null,
                "__hevo__ingested_at": null,
                "__hevo__marked_deleted": null,
                "__hevo__source_modified_at": null
            }
          ],
          group: group,
          programs: [
            {
                "id": 3528,
                "client_id": 207,
                "hysform_id": null,
                "donor_hysform_id": null,
                "setting_id": null,
                "emailset_id": null,
                "entity_submit": null,
                "donor_submit": null,
                "link_id": null,
                "name": "Testing Attachment Prog",
                "prefix": "TAP",
                "counter": null,
                "lft": 2,
                "rgt": 3,
                "tree": 207,
                "box_folder_id": null,
                "deleted_at": null,
                "created_at": "2024-07-26T05:43:51.000000Z",
                "updated_at": "2024-07-26T05:43:51.000000Z",
                "__hevo__database_name": null,
                "__hevo__ingested_at": null,
                "__hevo__marked_deleted": null,
                "__hevo__source_modified_at": null,
                "children": []
            },
            {
                "id": 3521,
                "client_id": 207,
                "hysform_id": 3051,
                "donor_hysform_id": 3030,
                "setting_id": 1652,
                "emailset_id": null,
                "entity_submit": null,
                "donor_submit": null,
                "link_id": null,
                "name": "Sustainable Living Expo",
                "prefix": "DC",
                "counter": 1,
                "lft": 4,
                "rgt": 5,
                "tree": 207,
                "box_folder_id": null,
                "deleted_at": null,
                "created_at": "2024-06-13T08:35:10.000000Z",
                "updated_at": "2024-08-06T12:03:55.000000Z",
                "__hevo__database_name": null,
                "__hevo__ingested_at": null,
                "__hevo__marked_deleted": null,
                "__hevo__source_modified_at": null,
                "children": []
            },
            {
                "id": 3518,
                "client_id": 207,
                "hysform_id": 3048,
                "donor_hysform_id": 639,
                "setting_id": 1650,
                "emailset_id": null,
                "entity_submit": null,
                "donor_submit": null,
                "link_id": null,
                "name": "Masaka BoreHole",
                "prefix": "MBH",
                "counter": 1,
                "lft": 6,
                "rgt": 7,
                "tree": 207,
                "box_folder_id": null,
                "deleted_at": null,
                "created_at": "2023-10-17T06:26:48.000000Z",
                "updated_at": "2024-06-17T12:10:38.000000Z",
                "__hevo__database_name": null,
                "__hevo__ingested_at": null,
                "__hevo__marked_deleted": null,
                "__hevo__source_modified_at": null,
                "children": []
            },
            {
                "id": 3510,
                "client_id": 207,
                "hysform_id": 3043,
                "donor_hysform_id": null,
                "setting_id": 410,
                "emailset_id": null,
                "entity_submit": null,
                "donor_submit": null,
                "link_id": null,
                "name": "us2uganda",
                "prefix": "usa",
                "counter": null,
                "lft": 8,
                "rgt": 9,
                "tree": 207,
                "box_folder_id": null,
                "deleted_at": null,
                "created_at": "2023-06-02T09:24:28.000000Z",
                "updated_at": "2023-06-02T09:36:45.000000Z",
                "__hevo__database_name": null,
                "__hevo__ingested_at": null,
                "__hevo__marked_deleted": null,
                "__hevo__source_modified_at": null,
                "children": []
            },
            {
                "id": 3507,
                "client_id": 207,
                "hysform_id": 3019,
                "donor_hysform_id": 3040,
                "setting_id": 1646,
                "emailset_id": null,
                "entity_submit": null,
                "donor_submit": null,
                "link_id": null,
                "name": "Kupe",
                "prefix": "KUP",
                "counter": null,
                "lft": 10,
                "rgt": 11,
                "tree": 207,
                "box_folder_id": null,
                "deleted_at": null,
                "created_at": "2023-05-03T06:41:45.000000Z",
                "updated_at": "2024-07-25T08:23:28.000000Z",
                "__hevo__database_name": null,
                "__hevo__ingested_at": null,
                "__hevo__marked_deleted": null,
                "__hevo__source_modified_at": null,
                "children": []
            },
            {
                "id": 3506,
                "client_id": 207,
                "hysform_id": 3019,
                "donor_hysform_id": 3030,
                "setting_id": 410,
                "emailset_id": 1557,
                "entity_submit": "3058",
                "donor_submit": "",
                "link_id": null,
                "name": "Kupenda",
                "prefix": "KP",
                "counter": 200,
                "lft": 12,
                "rgt": 13,
                "tree": 207,
                "box_folder_id": null,
                "deleted_at": null,
                "created_at": "2023-04-29T15:08:37.000000Z",
                "updated_at": "2024-07-29T09:01:30.000000Z",
                "__hevo__database_name": null,
                "__hevo__ingested_at": null,
                "__hevo__marked_deleted": null,
                "__hevo__source_modified_at": null,
                "children": []
            },
            {
                "id": 3438,
                "client_id": 207,
                "hysform_id": 3019,
                "donor_hysform_id": 639,
                "setting_id": 1641,
                "emailset_id": 0,
                "entity_submit": "",
                "donor_submit": "",
                "link_id": 0,
                "name": "Marathon for the Disabled",
                "prefix": "C1",
                "counter": 5,
                "lft": 14,
                "rgt": 15,
                "tree": 207,
                "box_folder_id": "",
                "deleted_at": null,
                "created_at": "2022-12-21T16:50:33.000000Z",
                "updated_at": "2024-08-06T12:02:58.000000Z",
                "__hevo__database_name": "apphys_new",
                "__hevo__ingested_at": 1679910350683,
                "__hevo__marked_deleted": 0,
                "__hevo__source_modified_at": 1679909849000,
                "children": []
            },
            {
                "id": 3437,
                "client_id": 207,
                "hysform_id": 638,
                "donor_hysform_id": 0,
                "setting_id": 410,
                "emailset_id": 0,
                "entity_submit": "",
                "donor_submit": "",
                "link_id": 0,
                "name": "Medical",
                "prefix": "",
                "counter": 0,
                "lft": 16,
                "rgt": 17,
                "tree": 207,
                "box_folder_id": "",
                "deleted_at": null,
                "created_at": "2022-12-14T20:13:03.000000Z",
                "updated_at": "2023-03-28T18:44:07.000000Z",
                "__hevo__database_name": "apphys_new",
                "__hevo__ingested_at": 1680004248746,
                "__hevo__marked_deleted": 0,
                "__hevo__source_modified_at": 1680003847000,
                "children": []
            },
            {
                "id": 1221,
                "client_id": 207,
                "hysform_id": 3019,
                "donor_hysform_id": 639,
                "setting_id": 410,
                "emailset_id": 0,
                "entity_submit": "",
                "donor_submit": "",
                "link_id": 0,
                "name": "Sponsored",
                "prefix": "",
                "counter": 54,
                "lft": 18,
                "rgt": 19,
                "tree": 207,
                "box_folder_id": "",
                "deleted_at": null,
                "created_at": "2019-01-22T01:50:21.000000Z",
                "updated_at": "2024-07-26T09:58:46.000000Z",
                "__hevo__database_name": "apphys_new",
                "__hevo__ingested_at": 1679910350681,
                "__hevo__marked_deleted": 0,
                "__hevo__source_modified_at": 1679909849000,
                "children": []
            },
            {
                "id": 929,
                "client_id": 207,
                "hysform_id": 638,
                "donor_hysform_id": 639,
                "setting_id": 410,
                "emailset_id": 337,
                "entity_submit": "3058",
                "donor_submit": "",
                "link_id": 0,
                "name": "Education",
                "prefix": "",
                "counter": 429,
                "lft": 20,
                "rgt": 21,
                "tree": 207,
                "box_folder_id": "",
                "deleted_at": null,
                "created_at": "2017-02-23T01:40:22.000000Z",
                "updated_at": "2024-08-06T12:02:54.000000Z",
                "__hevo__database_name": "apphys_new",
                "__hevo__ingested_at": 1680004248741,
                "__hevo__marked_deleted": 0,
                "__hevo__source_modified_at": 1680003822000,
                "children": []
            },
            {
                "id": 3494,
                "client_id": 207,
                "hysform_id": 3019,
                "donor_hysform_id": 639,
                "setting_id": 410,
                "emailset_id": 1542,
                "entity_submit": "3058",
                "donor_submit": null,
                "link_id": 929,
                "name": "Youth Mentorship Program",
                "prefix": "",
                "counter": 5,
                "lft": 21,
                "rgt": 22,
                "tree": 207,
                "box_folder_id": null,
                "deleted_at": null,
                "created_at": "2023-03-25T09:14:29.000000Z",
                "updated_at": "2024-08-06T12:51:47.000000Z",
                "__hevo__database_name": null,
                "__hevo__ingested_at": null,
                "__hevo__marked_deleted": null,
                "__hevo__source_modified_at": null,
                "children": []
            }
          ],
        }
      }
      return new Response(200, {}, result)
    })
  
    server.put(`${apiPrefix}/admin/groups/{data.group_id}`, (schema, request) => {
      // TODO: Handle dynamic parameters from request.params
      return new Response(200, {}, adminData['admin/groups/*'])
    })
  
    server.delete(`${apiPrefix}/admin/groups/:group_id`, (schema, request) => {
      const group_id = request.params.group_id
      schema.db.groups.remove({id: group_id})
      return new Response(200, {}, true)
    })
  
    server.get(`${apiPrefix}/admin/groups`, (schema, request) => {
      const groups = schema.db.groups
      const result = {
        groups: groups
      }
      return new Response(200, {}, result)
    })
  
    server.get(`${apiPrefix}/admin/createGroup`, (schema) => {
      return new Response(200, {}, schema.db.createGroup[0])
    })
  
    server.get(`${apiPrefix}/user/{data.id}/activation/status`, (schema, request) => {
      // TODO: Handle dynamic parameters from request.params
      return new Response(200, {}, adminData['user/*/activation/status'])
    })
  
    server.post(`${apiPrefix}/user/{data.id}/send_verification_email`, (schema, request) => {
      // TODO: Handle dynamic parameters from request.params
      return new Response(200, {}, adminData['user/*/send_verification_email'])
    })
  
    server.get(`${apiPrefix}/admin/account/{id}/activate`, (schema, request) => {
      // TODO: Handle dynamic parameters from request.params
      return new Response(200, {}, adminData['admin/account/*/activate'])
    })
  
    server.get(`${apiPrefix}/admin/account/{id}/deactivate`, (schema, request) => {
      // TODO: Handle dynamic parameters from request.params
      return new Response(200, {}, adminData['admin/account/*/deactivate'])
    })
  }