import wildCardSearch from '@/utils/wildCardSearch'
import sortBy, { Primer } from '@/utils/sortBy'
import paginate from '@/utils/paginate'
import { Server, Response } from 'miragejs'
import { admins, emails, newsLetterData, newsLetterDetail } from '../data/crmData'

export default function crmFakeApi(server: Server, apiPrefix: string) {
    server.post(`${apiPrefix}/admin/email_manager`, () => {
        return new Response(200, {}, emails)
    })

    server.get(`${apiPrefix}/admin/email_manager/admins`, () => {
        return new Response(200, {}, admins)
    })

    server.post(`${apiPrefix}/admin/newsLetters`, (schema, {requestBody}) => {
        const body = JSON.parse(requestBody)
        const { pageIndex, pageSize, filterData } = body
    
        let filteredData = schema.db.newsLetterData
    
        if (filterData === 'sent') {
          filteredData = filteredData.filter(item => item.status === 'sent')
        } else if (filterData === 'drafts') {
          filteredData = filteredData.filter(item => item.status === 'draft')
        }
    
        // Implement pagination
        const startIndex = (pageIndex - 1) * pageSize
        const endIndex = startIndex + pageSize
        const paginatedData = filteredData.slice(startIndex, endIndex)
    
        // Update pagination information
        const totalItems =filteredData.length
        const totalPages = Math.ceil(totalItems / pageSize)
    
        const paginatedResponse = {
          ...newsLetterData,
          data: {
            ...newsLetterData,
            data: paginatedData,
            current_page: pageIndex,
            from: startIndex + 1,
            to: Math.min(endIndex, totalItems),
            last_page: totalPages,
            per_page: pageSize,
            total: totalItems,
            next_page_url: pageIndex < totalPages ? `api/admin/newsLetters?page=${pageIndex + 1}` : null,
            prev_page_url: pageIndex > 1 ? `api/admin/newsLetters?page=${pageIndex - 1}` : null,
          }
        }
    
        // Update links
        paginatedResponse.data.links = [
          {
            url: paginatedResponse.data.prev_page_url,
            label: "&laquo; Previous",
            active: false
          },
          ...Array.from({ length: totalPages }, (_, i) => ({
            url: `api/admin/newsLetters?page=${i + 1}`,
            label: (i + 1).toString(),
            active: i + 1 === pageIndex
          })),
          {
            url: paginatedResponse.data.next_page_url,
            label: "Next &raquo;",
            active: false
          }
        ]
    
        return new Response(200, {}, paginatedResponse)
      })
    
      server.get(`${apiPrefix}/admin/newsLetters/:id`, (schema, request) => {
        return new Response(200, {}, newsLetterDetail)
      })

    server.post(`${apiPrefix}/admin/send/newsLetter`, (schema, {requestBody}) => {
        const body = JSON.parse(requestBody)

        // Create a new newsletter entry
        const newNewsletter = {
            id: Math.floor(Math.random() * 10000) + 1, // Generate a random ID between 1 and 10000
            client_id: body.client_id,
            created_at: new Date().toISOString(),
            donor_emails: JSON.stringify(body.emails),
            donor_ids: JSON.stringify(body.to),
            message: body.message,
            sender_email: body.from,
            sender_name: "Leslie Jobs", // You might want to get this from somewhere else
            status: body.status,
            title: body.title,
            type: "newsletter",
            updated_at: new Date().toISOString()
        }

        // Add the new newsletter to the database
        schema.db.newsLetterData.insert(newNewsletter)

        return new Response(200, {}, true)
    })

    server.del(`${apiPrefix}/admin/newsLetters/:id`, (schema, request) => {
        const newsletter_id = request.params.id
        schema.db.newsLetterData.remove({ id: newsletter_id })
        return true
    })

    server.get(`${apiPrefix}/crm/dashboard`, (schema) => {
        return schema.db.crmDashboardData[0]
    })

    server.get(`${apiPrefix}/crm/calendar`, (schema) => schema.db.eventsData)

    server.post(`${apiPrefix}/crm/customers`, (schema, { requestBody }) => {
        const body = JSON.parse(requestBody)
        const { pageIndex, pageSize, sort, query } = body
        const { order, key } = sort
        const users = schema.db.userDetailData
        const sanitizeUsers = users.filter((elm) => typeof elm !== 'function')
        let data = sanitizeUsers
        let total = users.length

        if (key && order) {
            if (key !== 'lastOnline') {
                data.sort(
                    sortBy(key, order === 'desc', (a) =>
                        (a as string).toUpperCase()
                    )
                )
            } else {
                data.sort(sortBy(key, order === 'desc', parseInt as Primer))
            }
        }

        if (query) {
            data = wildCardSearch(data, query)
            total = data.length
        }

        data = paginate(data, pageSize, pageIndex)

        const responseData = {
            data: data,
            total: total,
        }
        return responseData
    })

    server.get(`${apiPrefix}/crm/customers-statistic`, () => {
        return {
            totalCustomers: {
                value: 2420,
                growShrink: 17.2,
            },
            activeCustomers: {
                value: 1897,
                growShrink: 32.7,
            },
            newCustomers: {
                value: 241,
                growShrink: -2.3,
            },
        }
    })

    server.get(
        `${apiPrefix}/crm/customer-details`,
        (schema, { queryParams }) => {
            const id = queryParams.id
            const user = schema.db.userDetailData.find(id as string)
            return user
        }
    )

    server.del(
        `${apiPrefix}/crm/customer/delete`,
        (schema, { requestBody }) => {
            const { id } = JSON.parse(requestBody)
            schema.db.userDetailData.remove({ id })
            return {}
        }
    )

    server.put(`${apiPrefix}/crm/customers`, (schema, { requestBody }) => {
        const data = JSON.parse(requestBody)
        const { id } = data
        schema.db.userDetailData.update({ id }, data)
        return {}
    })

    server.get(`${apiPrefix}/crm/mails`, (schema, { queryParams }) => {
        const { category } = queryParams
        let data = schema.db.mailData

        if (category === 'sentItem') {
            data = schema.db.mailData.where({ group: 'sentItem' })
        }

        if (category === 'deleted') {
            data = schema.db.mailData.where({ group: 'deleted' })
        }

        if (category === 'draft') {
            data = schema.db.mailData.where({ group: 'draft' })
        }

        if (category === 'starred') {
            data = schema.db.mailData.where({ starred: true })
        }

        if (
            category === 'work' ||
            category === 'private' ||
            category === 'important'
        ) {
            data = schema.db.mailData.where({ label: category })
        }

        return data
    })

    server.get(`${apiPrefix}/crm/mail`, (schema, { queryParams }) => {
        const id = queryParams.id
        const mail = schema.db.mailData.find(id as string)
        return mail
    })
}
