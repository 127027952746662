export const donations = {
    donations: [
        {
          "id": 379539,
          "date": "2024-07-09T00:00:00.000000Z",
          "type": "Sponsorship",
          "code": "",
          "designation": "Kelluckson Jack",
          "refunded": 0,
          "method": "Cash",
          "result": " (Transaction Reference = Do Not Charge was selected.)",
          "amount": "13.00",
          "donor": {
            "id": 26134,
            "hysform_id": 639,
            "name": "Test Mulimwa",
            "email": "TestMulimwa@gmail.com"
          }
        },
        {
          "id": 379538,
          "date": "2024-07-09T00:00:00.000000Z",
          "type": "Donation",
          "code": "",
          "designation": "Bag of Rice",
          "refunded": 0,
          "method": "Check",
          "result": " (Transaction Reference = Do Not Charge was selected.)",
          "amount": "12.00",
          "donor": {
            "id": 26133,
            "hysform_id": 639,
            "name": "Test Sponsor",
            "email": "SponsorTest@gmail.com"
          }
        },
        {
          "id": 379537,
          "date": "2024-06-26T10:51:42.000000Z",
          "type": "Sponsorship",
          "code": "",
          "designation": "Estelande Alfred",
          "refunded": 0,
          "method": "Credit Card",
          "result": "(Transaction Reference = ch_2PVtRx4bY5qBmxTl1asOWYlY)",
          "amount": "32.00",
          "donor": {
            "id": 17017,
            "hysform_id": 639,
            "name": "Test Donor Shaban Paypal, monthly",
            "email": "shabanlubanga@gmail.com"
          }
        },
        {
          "id": 379536,
          "date": "2024-06-26T10:36:48.000000Z",
          "type": "Sponsorship",
          "code": "",
          "designation": "Estelande Alfred",
          "refunded": 0,
          "method": "Credit Card",
          "result": "(Transaction Reference = ch_2PVtDX4bY5qBmxTl1X5RA2WJ)",
          "amount": "32.00",
          "donor": {
            "id": 17017,
            "hysform_id": 639,
            "name": "Test Donor Shaban Paypal, monthly",
            "email": "shabanlubanga@gmail.com"
          }
        },
        {
          "id": 379535,
          "date": "2024-06-26T10:35:45.000000Z",
          "type": "Sponsorship",
          "code": "",
          "designation": "Estelande Alfred",
          "refunded": 0,
          "method": "Credit Card",
          "result": "(Transaction Reference = ch_2PVtCW4bY5qBmxTl1K5vql7o)",
          "amount": "32.00",
          "donor": {
            "id": 17017,
            "hysform_id": 639,
            "name": "Test Donor Shaban Paypal, monthly",
            "email": "shabanlubanga@gmail.com"
          }
        },
        {
          "id": 379534,
          "date": "2024-06-26T10:35:04.000000Z",
          "type": "Sponsorship",
          "code": "",
          "designation": "Estelande Alfred",
          "refunded": 0,
          "method": "Credit Card",
          "result": "(Transaction Reference = ch_2PVtBr4bY5qBmxTl1w7AsxcF)",
          "amount": "32.00",
          "donor": {
            "id": 17017,
            "hysform_id": 639,
            "name": "Test Donor Shaban Paypal, monthly",
            "email": "shabanlubanga@gmail.com"
          }
        },
        {
          "id": 379533,
          "date": "2024-06-26T10:05:14.000000Z",
          "type": "Sponsorship",
          "code": "",
          "designation": "Estelande Alfred",
          "refunded": 0,
          "method": "Credit Card",
          "result": "(Transaction Reference = ch_2PVsj04bY5qBmxTl1evbELee)",
          "amount": "32.00",
          "donor": {
            "id": 17017,
            "hysform_id": 639,
            "name": "Test Donor Shaban Paypal, monthly",
            "email": "shabanlubanga@gmail.com"
          }
        },
        {
          "id": 379532,
          "date": "2024-06-26T10:04:17.000000Z",
          "type": "Sponsorship",
          "code": "",
          "designation": "Estelande Alfred",
          "refunded": 0,
          "method": "Credit Card",
          "result": "(Transaction Reference = ch_2PVsi44bY5qBmxTl12GUHqhy)",
          "amount": "32.00",
          "donor": {
            "id": 17017,
            "hysform_id": 639,
            "name": "Test Donor Shaban Paypal, monthly",
            "email": "shabanlubanga@gmail.com"
          }
        },
        {
          "id": 379531,
          "date": "2024-06-26T10:03:46.000000Z",
          "type": "Sponsorship",
          "code": "",
          "designation": "Estelande Alfred",
          "refunded": 0,
          "method": "Credit Card",
          "result": "(Transaction Reference = ch_2PVshZ4bY5qBmxTl1yLRqMVm)",
          "amount": "32.00",
          "donor": {
            "id": 17017,
            "hysform_id": 639,
            "name": "Test Donor Shaban Paypal, monthly",
            "email": "shabanlubanga@gmail.com"
          }
        },
        {
          "id": 379530,
          "date": "2024-06-26T09:56:41.000000Z",
          "type": "Sponsorship",
          "code": "",
          "designation": "Estelande Alfred",
          "refunded": 0,
          "method": "Credit Card",
          "result": "(Transaction Reference = ch_2PVsaj4bY5qBmxTl1iBDoui6)",
          "amount": "32.00",
          "donor": {
            "id": 17017,
            "hysform_id": 639,
            "name": "Test Donor Shaban Paypal, monthly",
            "email": "shabanlubanga@gmail.com"
          }
        },
        {
          "id": 379529,
          "date": "2024-06-26T09:55:34.000000Z",
          "type": "Sponsorship",
          "code": "",
          "designation": "Estelande Alfred",
          "refunded": 0,
          "method": "Credit Card",
          "result": "(Transaction Reference = ch_2PVsZd4bY5qBmxTl1S0oB2WI)",
          "amount": "32.00",
          "donor": {
            "id": 17017,
            "hysform_id": 639,
            "name": "Test Donor Shaban Paypal, monthly",
            "email": "shabanlubanga@gmail.com"
          }
        },
        {
          "id": 379528,
          "date": "2024-06-26T07:26:36.000000Z",
          "type": "Sponsorship",
          "code": "",
          "designation": "Estelande Alfred",
          "refunded": 0,
          "method": "Credit Card",
          "result": "(Transaction Reference = ch_2PVqFT4bY5qBmxTl0pNUnV6D)",
          "amount": "32.00",
          "donor": {
            "id": 17017,
            "hysform_id": 639,
            "name": "Test Donor Shaban Paypal, monthly",
            "email": "shabanlubanga@gmail.com"
          }
        },
        {
          "id": 379527,
          "date": "2024-06-24T20:08:41.000000Z",
          "type": "Sponsorship",
          "code": "",
          "designation": "Estelande Alfred",
          "refunded": 0,
          "method": "Credit Card",
          "result": "(Transaction Reference = ch_2PVJBs4bY5qBmxTl0XDoJkm9)",
          "amount": "32.00",
          "donor": {
            "id": 26087,
            "hysform_id": 639,
            "name": "Schade",
            "email": "s.charlesderrick9@gmail.com"
          }
        },
        {
          "id": 379526,
          "date": "2024-06-24T20:06:54.000000Z",
          "type": "Sponsorship",
          "code": "",
          "designation": "Estelande Alfred",
          "refunded": 0,
          "method": "Credit Card",
          "result": "(Transaction Reference = ch_2PVJA94bY5qBmxTl1XDFdsEz)",
          "amount": "32.00",
          "donor": {
            "id": 26087,
            "hysform_id": 639,
            "name": "Schade",
            "email": "s.charlesderrick9@gmail.com"
          }
        },
        {
          "id": 379523,
          "date": "2024-06-24T05:50:19.000000Z",
          "type": "Sponsorship",
          "code": "",
          "designation": "Jackendy Pierre",
          "refunded": 0,
          "method": "Credit Card",
          "result": "(Transaction Reference = ch_2PV5nC4bY5qBmxTl1AgJanGq)",
          "amount": "32.00",
          "donor": {
            "id": 17017,
            "hysform_id": 639,
            "name": "Test Donor Shaban Paypal, monthly",
            "email": "shabanlubanga@gmail.com"
          }
        },
        {
          "id": 379522,
          "date": "2024-06-24T05:48:46.000000Z",
          "type": "Sponsorship",
          "code": "",
          "designation": "Jackendy Pierre",
          "refunded": 0,
          "method": "Credit Card",
          "result": "(Transaction Reference = ch_2PV5li4bY5qBmxTl0mCqCty8)",
          "amount": "32.00",
          "donor": {
            "id": 17017,
            "hysform_id": 639,
            "name": "Test Donor Shaban Paypal, monthly",
            "email": "shabanlubanga@gmail.com"
          }
        },
        {
          "id": 379525,
          "date": "2024-06-24T00:00:00.000000Z",
          "type": "Sponsorship",
          "code": "",
          "designation": "Choudna Pierre",
          "refunded": 0,
          "method": "Cash",
          "result": "<p>hello</p> (Transaction Reference = Do Not Charge was selected.)",
          "amount": "10.00",
          "donor": {
            "id": 17166,
            "hysform_id": 639,
            "name": "John, Katie & Clara Richter CC Monthly",
            "email": "dev+6@helpyousponsor.com"
          }
        },
        {
          "id": 379524,
          "date": "2024-06-24T00:00:00.000000Z",
          "type": "Sponsorship",
          "code": "",
          "designation": "Choudna Pierre",
          "refunded": 0,
          "method": "Cash",
          "result": "<p>hello</p> (Transaction Reference = Do Not Charge was selected.)",
          "amount": "10.00",
          "donor": {
            "id": 17166,
            "hysform_id": 639,
            "name": "John, Katie & Clara Richter CC Monthly",
            "email": "dev+6@helpyousponsor.com"
          }
        },
        {
          "id": 379521,
          "date": "2024-06-24T00:00:00.000000Z",
          "type": "Sponsorship",
          "code": "",
          "designation": "Claudania Estilien",
          "refunded": 0,
          "method": "Check",
          "result": " (Transaction Reference = Do Not Charge was selected.)",
          "amount": "67.00",
          "donor": {
            "id": 16924,
            "hysform_id": 639,
            "name": "Thad & Test Collins Paypal",
            "email": "dev+4@helpyousponsor.com"
          }
        },
        {
          "id": 379520,
          "date": "2024-06-17T09:21:40.000000Z",
          "type": "Sponsorship",
          "code": "",
          "designation": "Dalerson Jean",
          "refunded": 0,
          "method": "Credit Card",
          "result": "(Transaction Reference = ch_2PSbku4bY5qBmxTl080tbpCm)",
          "amount": "32.00",
          "donor": {
            "id": 26125,
            "hysform_id": 639,
            "name": "Test Gerald Opio",
            "email": "TestGeraldOpio@gmail.com"
          }
        },
        {
          "id": 379519,
          "date": "2024-06-13T11:51:01.000000Z",
          "type": "Donation",
          "code": "",
          "designation": "",
          "refunded": 0,
          "method": "Credit Card",
          "result": "(Transaction Reference = ch_2PRCBE4bY5qBmxTl1yTi6NCI)",
          "amount": "52.94",
          "donor": {
            "id": 17166,
            "hysform_id": 639,
            "name": "John, Katie & Clara Richter CC Monthly",
            "email": "dev+6@helpyousponsor.com"
          }
        },
        {
          "id": 379518,
          "date": "2024-06-07T00:00:00.000000Z",
          "type": "Sponsorship",
          "code": "",
          "designation": "Choudna Pierre",
          "refunded": 0,
          "method": "Credit Card",
          "result": "<p>Hello</p> (Transaction Reference = ch_2PP8iX4bY5qBmxTl0e2y0smb)",
          "amount": "10.00",
          "donor": {
            "id": 17166,
            "hysform_id": 639,
            "name": "John, Katie & Clara Richter CC Monthly",
            "email": "dev+6@helpyousponsor.com"
          }
        },
        {
          "id": 379516,
          "date": "2024-05-23T00:00:00.000000Z",
          "type": "Sponsorship",
          "code": "",
          "designation": "Echelanda Jean-Baptiste",
          "refunded": 0,
          "method": "Credit Card",
          "result": "<p>(Transaction Reference = ch_2PGDaN4bY5qBmxTl0lYWNYtf)</p>",
          "amount": "80.00",
          "donor": {
            "id": 26112,
            "hysform_id": "",
            "name": "No Sponsor Name Found",
            "email": ""
          }
        },
        {
          "id": 379509,
          "date": "2024-05-23T00:00:00.000000Z",
          "type": "Donation",
          "code": "",
          "designation": "Bag of Rice",
          "refunded": 0,
          "method": "Cash",
          "result": " (Transaction Reference = Do Not Charge was selected.)",
          "amount": "45.00",
          "donor": {
            "id": 26111,
            "hysform_id": "",
            "name": "No Sponsor Name Found",
            "email": ""
          }
        },
        {
          "id": 379511,
          "date": "2024-05-22T00:00:00.000000Z",
          "type": "Sponsorship",
          "code": "",
          "designation": "Echelanda Jean-Baptiste",
          "refunded": 0,
          "method": "Credit Card",
          "result": " (Transaction Reference = ch_2PFxLm4bY5qBmxTl0sepzap4)",
          "amount": "22.00",
          "donor": {
            "id": 26111,
            "hysform_id": "",
            "name": "No Sponsor Name Found",
            "email": ""
          }
        },
        {
          "id": 379502,
          "date": "2024-05-17T00:00:00.000000Z",
          "type": "Sponsorship",
          "code": "",
          "designation": "Malache Basquin",
          "refunded": 0,
          "method": "External Processor",
          "result": "<p>(Transaction Reference = Do Not Charge was selected.)</p>",
          "amount": "56.00",
          "donor": {
            "id": 17017,
            "hysform_id": 639,
            "name": "Test Donor Shaban Paypal, monthly",
            "email": "shabanlubanga@gmail.com"
          }
        },
        {
          "id": 379517,
          "date": "2024-05-16T00:00:00.000000Z",
          "type": "Sponsorship",
          "code": "",
          "designation": "Jonalis Jude-Na\u00edca",
          "refunded": 0,
          "method": "External Processor",
          "result": " ",
          "amount": "35.00",
          "donor": {
            "id": 17017,
            "hysform_id": 639,
            "name": "Test Donor Shaban Paypal, monthly",
            "email": "shabanlubanga@gmail.com"
          }
        },
        {
          "id": 379513,
          "date": "2024-05-16T00:00:00.000000Z",
          "type": "Donation",
          "code": "",
          "designation": "Bag of Rice",
          "refunded": 0,
          "method": "Wire Transfer",
          "result": "<p>(Transaction Reference = Do Not Charge was selected.)</p>",
          "amount": "50.00",
          "donor": {
            "id": 26112,
            "hysform_id": "",
            "name": "No Sponsor Name Found",
            "email": ""
          }
        },
        {
          "id": 379503,
          "date": "2024-05-15T00:00:00.000000Z",
          "type": "Donation",
          "code": "",
          "designation": "Bag of Rice",
          "refunded": 0,
          "method": "Credit Card",
          "result": "<p>test</p><p>(Transaction Reference = Do Not Charge was selected.)</p>",
          "amount": "50.00",
          "donor": {
            "id": 26111,
            "hysform_id": "",
            "name": "No Sponsor Name Found",
            "email": ""
          }
        },
        {
          "id": 379515,
          "date": "2024-05-14T00:00:00.000000Z",
          "type": "Sponsorship",
          "code": "",
          "designation": "Echelanda Jean-Baptiste",
          "refunded": 0,
          "method": "Credit Card",
          "result": " (Transaction Reference = ch_2PGDa44bY5qBmxTl0fP8y6sg)",
          "amount": "32.00",
          "donor": {
            "id": 26112,
            "hysform_id": "",
            "name": "No Sponsor Name Found",
            "email": ""
          }
        },
        {
          "id": 379514,
          "date": "2024-05-14T00:00:00.000000Z",
          "type": "Sponsorship",
          "code": "",
          "designation": "Sophia Saint-Louis",
          "refunded": 0,
          "method": "Credit Card",
          "result": " (Transaction Reference = ch_2PGDZf4bY5qBmxTl0j8pYjI2)",
          "amount": "32.00",
          "donor": {
            "id": 26112,
            "hysform_id": "",
            "name": "No Sponsor Name Found",
            "email": ""
          }
        },
        {
          "id": 379512,
          "date": "2024-05-13T12:06:54.000000Z",
          "type": "Sponsorship",
          "code": "",
          "designation": "Louidjivenson Vilsaint",
          "refunded": 0,
          "method": "Credit Card",
          "result": "( Every Month )  Transaction Reference = ch_2PFxeb4bY5qBmxTl1sa0TvvX [card last digits 4242 with expiry 12/2031]",
          "amount": "32.00",
          "donor": {
            "id": 26087,
            "hysform_id": 639,
            "name": "Schade",
            "email": "s.charlesderrick9@gmail.com"
          }
        },
        {
          "id": 379510,
          "date": "2024-05-13T00:00:00.000000Z",
          "type": "Sponsorship",
          "code": "",
          "designation": "Echelanda Jean-Baptiste",
          "refunded": 0,
          "method": "Credit Card",
          "result": " (Transaction Reference = ch_2PFxLN4bY5qBmxTl0LIENMts)",
          "amount": "90.00",
          "donor": {
            "id": 26111,
            "hysform_id": "",
            "name": "No Sponsor Name Found",
            "email": ""
          }
        },
        {
          "id": 379508,
          "date": "2024-05-13T00:00:00.000000Z",
          "type": "Donation",
          "code": "",
          "designation": "Bag of Beans",
          "refunded": 0,
          "method": "Credit Card",
          "result": "<p>(Transaction Reference = ch_2PFxIk4bY5qBmxTl197Oxppb)</p>",
          "amount": "50.00",
          "donor": {
            "id": 26111,
            "hysform_id": "",
            "name": "No Sponsor Name Found",
            "email": ""
          }
        },
        {
          "id": 379507,
          "date": "2024-05-13T00:00:00.000000Z",
          "type": "Sponsorship",
          "code": "",
          "designation": "Wismika Chery",
          "refunded": 0,
          "method": "Credit Card",
          "result": " (Transaction Reference = ch_2PFxFP4bY5qBmxTl0254QySH)",
          "amount": "80.00",
          "donor": {
            "id": 26111,
            "hysform_id": "",
            "name": "No Sponsor Name Found",
            "email": ""
          }
        },
        {
          "id": 379506,
          "date": "2024-05-13T00:00:00.000000Z",
          "type": "Sponsorship",
          "code": "",
          "designation": "Wismika Chery",
          "refunded": 0,
          "method": "Credit Card",
          "result": " (Transaction Reference = ch_2PFxCb4bY5qBmxTl0zUfgXrA)",
          "amount": "36.00",
          "donor": {
            "id": 26111,
            "hysform_id": "",
            "name": "No Sponsor Name Found",
            "email": ""
          }
        },
        {
          "id": 379505,
          "date": "2024-05-13T00:00:00.000000Z",
          "type": "Sponsorship",
          "code": "",
          "designation": "Wismika Chery",
          "refunded": 0,
          "method": "Credit Card",
          "result": " (Transaction Reference = ch_2PFxCC4bY5qBmxTl0MX7JmN1)",
          "amount": "32.00",
          "donor": {
            "id": 26111,
            "hysform_id": "",
            "name": "No Sponsor Name Found",
            "email": ""
          }
        },
        {
          "id": 379501,
          "date": "2024-05-13T00:00:00.000000Z",
          "type": "Sponsorship",
          "code": "",
          "designation": "Isedervensley Saint-Julles",
          "refunded": 1,
          "method": "Credit Card",
          "result": "( Refunded 37 )  Transaction Reference = ch_2PFvGM4bY5qBmxTl0c7JeIUI",
          "amount": "37.00",
          "donor": {
            "id": 17017,
            "hysform_id": 639,
            "name": "Test Donor Shaban Paypal, monthly",
            "email": "shabanlubanga@gmail.com"
          }
        },
        {
          "id": 379500,
          "date": "2024-05-13T00:00:00.000000Z",
          "type": "Sponsorship",
          "code": "",
          "designation": "Peterson Jean",
          "refunded": 0,
          "method": "Credit Card",
          "result": "<p>test</p> (Transaction Reference = ch_2PFvEI4bY5qBmxTl01yuGBlX)",
          "amount": "34.00",
          "donor": {
            "id": 17017,
            "hysform_id": 639,
            "name": "Test Donor Shaban Paypal, monthly",
            "email": "shabanlubanga@gmail.com"
          }
        },
        {
          "id": 379499,
          "date": "2024-05-12T12:07:07.000000Z",
          "type": "Sponsorship",
          "code": "",
          "designation": "Dahenslow Etienne",
          "refunded": 0,
          "method": "Credit Card",
          "result": "( Every Month )  Transaction Reference = ch_2PFbBG4bY5qBmxTl1Pot0F8F [card last digits 4242 with expiry 9/2028]",
          "amount": "32.00",
          "donor": {
            "id": 26094,
            "hysform_id": 639,
            "name": "Test Evelyn",
            "email": "evetest@gmail.com"
          }
        },
        {
          "id": 379498,
          "date": "2024-05-12T12:06:49.000000Z",
          "type": "Sponsorship",
          "code": "",
          "designation": "Wilna Dervil",
          "refunded": 0,
          "method": "Credit Card",
          "result": "( Every Month )  Transaction Reference = ch_2PFbAx4bY5qBmxTl15jO9cpt [card last digits 4242 with expiry 2/2025]",
          "amount": "32.00",
          "donor": {
            "id": 17166,
            "hysform_id": 639,
            "name": "John, Katie & Clara Richter CC Monthly",
            "email": "dev+6@helpyousponsor.com"
          }
        },
        {
          "id": 379470,
          "date": "2024-05-10T00:00:00.000000Z",
          "type": "Sponsorship",
          "code": "",
          "designation": "Djedjilove Auralus",
          "refunded": 0,
          "method": "Wire Transfer",
          "result": " (Transaction Reference = Do Not Charge was selected.)",
          "amount": "50.00",
          "donor": {
            "id": 17135,
            "hysform_id": 639,
            "name": "Dianne Test CC Monthly",
            "email": "dev+5@helpyousponsor.com"
          }
        },
        {
          "id": 379496,
          "date": "2024-05-09T12:07:28.000000Z",
          "type": "Sponsorship",
          "code": "",
          "designation": "Djouninho Saint-Juste",
          "refunded": 0,
          "method": "Credit Card",
          "result": "( Every Month )  Transaction Reference = ch_2PEVkx4bY5qBmxTl0KHa7ibt [card last digits 4242 with expiry 9/2028]",
          "amount": "32.00",
          "donor": {
            "id": 26094,
            "hysform_id": 639,
            "name": "Test Evelyn",
            "email": "evetest@gmail.com"
          }
        },
        {
          "id": 379495,
          "date": "2024-05-09T12:07:25.000000Z",
          "type": "Sponsorship",
          "code": "",
          "designation": "Rose-Landa Auguste",
          "refunded": 0,
          "method": "Credit Card",
          "result": "( Every Month )  Transaction Reference = ch_2PEVku4bY5qBmxTl0xcAE5tL [card last digits 4242 with expiry 9/2028]",
          "amount": "32.00",
          "donor": {
            "id": 26094,
            "hysform_id": 639,
            "name": "Test Evelyn",
            "email": "evetest@gmail.com"
          }
        },
        {
          "id": 379494,
          "date": "2024-05-09T12:07:21.000000Z",
          "type": "Sponsorship",
          "code": "",
          "designation": "Loudemiya Rich\u00e9",
          "refunded": 0,
          "method": "Credit Card",
          "result": "( Every Month )  Transaction Reference = ch_2PEVkq4bY5qBmxTl1PKwX35y [card last digits 4242 with expiry 9/2028]",
          "amount": "32.00",
          "donor": {
            "id": 26093,
            "hysform_id": 639,
            "name": "Test Zawede",
            "email": "testzawede@gmail.com"
          }
        },
        {
          "id": 379493,
          "date": "2024-05-09T12:07:18.000000Z",
          "type": "Sponsorship",
          "code": "",
          "designation": "Judena\u00eflie DuMassair",
          "refunded": 0,
          "method": "Credit Card",
          "result": "( Every Month )  Transaction Reference = ch_2PEVkn4bY5qBmxTl0iqAi6h6 [card last digits 4242 with expiry 9/2028]",
          "amount": "32.00",
          "donor": {
            "id": 26093,
            "hysform_id": 639,
            "name": "Test Zawede",
            "email": "testzawede@gmail.com"
          }
        },
        {
          "id": 379488,
          "date": "2024-05-07T12:07:56.000000Z",
          "type": "Sponsorship",
          "code": "",
          "designation": "Masaka BoreHole",
          "refunded": 0,
          "method": "Credit Card",
          "result": "( Every Month )  Transaction Reference = ch_2PDmoJ4bY5qBmxTl1rYlVQpG [card last digits 4242 with expiry 12/2031]",
          "amount": "105.00",
          "donor": {
            "id": 26087,
            "hysform_id": 639,
            "name": "Schade",
            "email": "s.charlesderrick9@gmail.com"
          }
        },
        {
          "id": 379487,
          "date": "2024-05-07T12:07:53.000000Z",
          "type": "Sponsorship",
          "code": "",
          "designation": "Masaka BoreHole",
          "refunded": 0,
          "method": "Credit Card",
          "result": "( Every Month )  Transaction Reference = ch_2PDmoG4bY5qBmxTl02KaKavt [card last digits 4242 with expiry 12/2031]",
          "amount": "262.50",
          "donor": {
            "id": 26087,
            "hysform_id": 639,
            "name": "Schade",
            "email": "s.charlesderrick9@gmail.com"
          }
        },
        {
          "id": 379486,
          "date": "2024-05-07T12:07:51.000000Z",
          "type": "Sponsorship",
          "code": "",
          "designation": "Louidjivenson Vilsaint",
          "refunded": 0,
          "method": "Credit Card",
          "result": "( Every Month )  Transaction Reference = ch_2PDmoD4bY5qBmxTl1rfE2Wmm [card last digits 4242 with expiry 12/2031]",
          "amount": "32.00",
          "donor": {
            "id": 26087,
            "hysform_id": 639,
            "name": "Schade",
            "email": "s.charlesderrick9@gmail.com"
          }
        },
        {
          "id": 379485,
          "date": "2024-05-07T12:07:48.000000Z",
          "type": "Sponsorship",
          "code": "",
          "designation": "Wilna Dervil",
          "refunded": 0,
          "method": "Credit Card",
          "result": "( Every Month )  Transaction Reference = ch_2PDmoA4bY5qBmxTl1LF3nNXr [card last digits 4242 with expiry 2/2025]",
          "amount": "32.00",
          "donor": {
            "id": 17166,
            "hysform_id": 639,
            "name": "John, Katie & Clara Richter CC Monthly",
            "email": "dev+6@helpyousponsor.com"
          }
        },
        {
          "id": 379484,
          "date": "2024-05-07T09:58:13.000000Z",
          "type": "Sponsorship",
          "code": "",
          "designation": "Carlensky Basquin",
          "refunded": 0,
          "method": "Credit Card",
          "result": "(Transaction Reference = ch_2PDkmm4bY5qBmxTl0yxBIOjE)",
          "amount": "32.00",
          "donor": {
            "id": 17166,
            "hysform_id": 639,
            "name": "John, Katie & Clara Richter CC Monthly",
            "email": "dev+6@helpyousponsor.com"
          }
        },
        {
          "id": 379483,
          "date": "2024-05-07T09:53:37.000000Z",
          "type": "Sponsorship",
          "code": "",
          "designation": "Edena\u00eflie Jean",
          "refunded": 0,
          "method": "Credit Card",
          "result": "(Transaction Reference = ch_2PDkiK4bY5qBmxTl0pKs10sM)",
          "amount": "32.00",
          "donor": {
            "id": 17166,
            "hysform_id": 639,
            "name": "John, Katie & Clara Richter CC Monthly",
            "email": "dev+6@helpyousponsor.com"
          }
        },
        {
          "id": 379482,
          "date": "2024-05-06T12:07:34.000000Z",
          "type": "Sponsorship",
          "code": "",
          "designation": "Masaka BoreHole",
          "refunded": 0,
          "method": "Credit Card",
          "result": "( Every Month )  Transaction Reference = ch_2PDQKP4bY5qBmxTl0iWo4kZG [card last digits 4242 with expiry 12/2031]",
          "amount": "105.00",
          "donor": {
            "id": 26087,
            "hysform_id": 639,
            "name": "Schade",
            "email": "s.charlesderrick9@gmail.com"
          }
        },
        {
          "id": 379481,
          "date": "2024-05-06T12:07:31.000000Z",
          "type": "Sponsorship",
          "code": "",
          "designation": "Masaka BoreHole",
          "refunded": 0,
          "method": "Credit Card",
          "result": "( Every Month )  Transaction Reference = ch_2PDQKM4bY5qBmxTl0gG21qOA [card last digits 4242 with expiry 12/2031]",
          "amount": "262.50",
          "donor": {
            "id": 26087,
            "hysform_id": 639,
            "name": "Schade",
            "email": "s.charlesderrick9@gmail.com"
          }
        },
        {
          "id": 379480,
          "date": "2024-05-06T12:07:28.000000Z",
          "type": "Sponsorship",
          "code": "",
          "designation": "Louidjivenson Vilsaint",
          "refunded": 0,
          "method": "Credit Card",
          "result": "( Every Month )  Transaction Reference = ch_2PDQKJ4bY5qBmxTl0nUwC6E8 [card last digits 4242 with expiry 12/2031]",
          "amount": "32.00",
          "donor": {
            "id": 26087,
            "hysform_id": 639,
            "name": "Schade",
            "email": "s.charlesderrick9@gmail.com"
          }
        },
        {
          "id": 379479,
          "date": "2024-05-06T12:07:25.000000Z",
          "type": "Sponsorship",
          "code": "",
          "designation": "Wilna Dervil",
          "refunded": 0,
          "method": "Credit Card",
          "result": "( Every Month )  Transaction Reference = ch_2PDQKG4bY5qBmxTl1t561YCO [card last digits 4242 with expiry 2/2025]",
          "amount": "32.00",
          "donor": {
            "id": 17166,
            "hysform_id": 639,
            "name": "John, Katie & Clara Richter CC Monthly",
            "email": "dev+6@helpyousponsor.com"
          }
        },
        {
          "id": 379478,
          "date": "2024-05-05T12:07:16.000000Z",
          "type": "Sponsorship",
          "code": "",
          "designation": "Masaka BoreHole",
          "refunded": 0,
          "method": "Credit Card",
          "result": "( Every Month )  Transaction Reference = ch_2PD3qZ4bY5qBmxTl1cSoMkH4 [card last digits 4242 with expiry 12/2031]",
          "amount": "105.00",
          "donor": {
            "id": 26087,
            "hysform_id": 639,
            "name": "Schade",
            "email": "s.charlesderrick9@gmail.com"
          }
        },
        {
          "id": 379477,
          "date": "2024-05-05T12:07:13.000000Z",
          "type": "Sponsorship",
          "code": "",
          "designation": "Masaka BoreHole",
          "refunded": 0,
          "method": "Credit Card",
          "result": "( Every Month )  Transaction Reference = ch_2PD3qW4bY5qBmxTl0RLaa9ak [card last digits 4242 with expiry 12/2031]",
          "amount": "262.50",
          "donor": {
            "id": 26087,
            "hysform_id": 639,
            "name": "Schade",
            "email": "s.charlesderrick9@gmail.com"
          }
        },
        {
          "id": 379476,
          "date": "2024-05-05T12:07:11.000000Z",
          "type": "Sponsorship",
          "code": "",
          "designation": "Louidjivenson Vilsaint",
          "refunded": 0,
          "method": "Credit Card",
          "result": "( Every Month )  Transaction Reference = ch_2PD3qT4bY5qBmxTl0HoT5TOw [card last digits 4242 with expiry 12/2031]",
          "amount": "32.00",
          "donor": {
            "id": 26087,
            "hysform_id": 639,
            "name": "Schade",
            "email": "s.charlesderrick9@gmail.com"
          }
        },
        {
          "id": 379475,
          "date": "2024-05-05T12:07:07.000000Z",
          "type": "Sponsorship",
          "code": "",
          "designation": "Wilna Dervil",
          "refunded": 0,
          "method": "Credit Card",
          "result": "( Every Month )  Transaction Reference = ch_2PD3qQ4bY5qBmxTl1fXGGFxg [card last digits 4242 with expiry 2/2025]",
          "amount": "32.00",
          "donor": {
            "id": 17166,
            "hysform_id": 639,
            "name": "John, Katie & Clara Richter CC Monthly",
            "email": "dev+6@helpyousponsor.com"
          }
        },
        {
          "id": 379474,
          "date": "2024-05-04T12:07:04.000000Z",
          "type": "Sponsorship",
          "code": "",
          "designation": "Masaka BoreHole",
          "refunded": 0,
          "method": "Credit Card",
          "result": "( Every Month )  Transaction Reference = ch_2PChMq4bY5qBmxTl1al8oFNf [card last digits 4242 with expiry 12/2031]",
          "amount": "105.00",
          "donor": {
            "id": 26087,
            "hysform_id": 639,
            "name": "Schade",
            "email": "s.charlesderrick9@gmail.com"
          }
        },
        {
          "id": 379473,
          "date": "2024-05-04T12:07:02.000000Z",
          "type": "Sponsorship",
          "code": "",
          "designation": "Masaka BoreHole",
          "refunded": 0,
          "method": "Credit Card",
          "result": "( Every Month )  Transaction Reference = ch_2PChMn4bY5qBmxTl0Z3OiksO [card last digits 4242 with expiry 12/2031]",
          "amount": "262.50",
          "donor": {
            "id": 26087,
            "hysform_id": 639,
            "name": "Schade",
            "email": "s.charlesderrick9@gmail.com"
          }
        },
        {
          "id": 379472,
          "date": "2024-05-04T12:06:59.000000Z",
          "type": "Sponsorship",
          "code": "",
          "designation": "Louidjivenson Vilsaint",
          "refunded": 0,
          "method": "Credit Card",
          "result": "( Every Month )  Transaction Reference = ch_2PChMk4bY5qBmxTl0INJnMl0 [card last digits 4242 with expiry 12/2031]",
          "amount": "32.00",
          "donor": {
            "id": 26087,
            "hysform_id": 639,
            "name": "Schade",
            "email": "s.charlesderrick9@gmail.com"
          }
        },
        {
          "id": 379471,
          "date": "2024-05-04T12:06:56.000000Z",
          "type": "Sponsorship",
          "code": "",
          "designation": "Wilna Dervil",
          "refunded": 0,
          "method": "Credit Card",
          "result": "( Every Month )  Transaction Reference = ch_2PChMh4bY5qBmxTl1xODpR77 [card last digits 4242 with expiry 2/2025]",
          "amount": "32.00",
          "donor": {
            "id": 17166,
            "hysform_id": 639,
            "name": "John, Katie & Clara Richter CC Monthly",
            "email": "dev+6@helpyousponsor.com"
          }
        },
        {
          "id": 379464,
          "date": "2024-05-04T00:00:00.000000Z",
          "type": "Sponsorship",
          "code": "",
          "designation": "Djedjilove Auralus",
          "refunded": 0,
          "method": "Credit Card",
          "result": "<p>test payment 2</p> (Transaction Reference = ch_2PC8K34bY5qBmxTl1w4xJIsU)",
          "amount": "32.00",
          "donor": {
            "id": 17135,
            "hysform_id": 639,
            "name": "Dianne Test CC Monthly",
            "email": "dev+5@helpyousponsor.com"
          }
        },
        {
          "id": 379462,
          "date": "2024-05-04T00:00:00.000000Z",
          "type": "Sponsorship",
          "code": "",
          "designation": "Alvens John Kennley Estil",
          "refunded": 0,
          "method": "Credit Card",
          "result": "<p>Test donaiton 2</p> (Transaction Reference = Do Not Charge was selected.)",
          "amount": "11.00",
          "donor": {
            "id": 17135,
            "hysform_id": 639,
            "name": "Dianne Test CC Monthly",
            "email": "dev+5@helpyousponsor.com"
          }
        },
        {
          "id": 379469,
          "date": "2024-05-03T12:08:17.000000Z",
          "type": "Sponsorship",
          "code": "",
          "designation": "Choudna Pierre",
          "refunded": 0,
          "method": "Credit Card",
          "result": "( Every Month )  Transaction Reference = ch_2PCKuS4bY5qBmxTl1AWaEtyc [card last digits 4242 with expiry 2/2025]",
          "amount": "32.00",
          "donor": {
            "id": 17166,
            "hysform_id": 639,
            "name": "John, Katie & Clara Richter CC Monthly",
            "email": "dev+6@helpyousponsor.com"
          }
        },
        {
          "id": 379468,
          "date": "2024-05-03T12:07:14.000000Z",
          "type": "Sponsorship",
          "code": "",
          "designation": "Masaka BoreHole",
          "refunded": 0,
          "method": "Credit Card",
          "result": "( Every Month )  Transaction Reference = ch_2PCKtR4bY5qBmxTl0aWe1QpI [card last digits 4242 with expiry 12/2031]",
          "amount": "105.00",
          "donor": {
            "id": 26087,
            "hysform_id": 639,
            "name": "Schade",
            "email": "s.charlesderrick9@gmail.com"
          }
        },
        {
          "id": 379467,
          "date": "2024-05-03T12:07:11.000000Z",
          "type": "Sponsorship",
          "code": "",
          "designation": "Masaka BoreHole",
          "refunded": 0,
          "method": "Credit Card",
          "result": "( Every Month )  Transaction Reference = ch_2PCKtO4bY5qBmxTl02OL8Zs4 [card last digits 4242 with expiry 12/2031]",
          "amount": "262.50",
          "donor": {
            "id": 26087,
            "hysform_id": 639,
            "name": "Schade",
            "email": "s.charlesderrick9@gmail.com"
          }
        },
        {
          "id": 379466,
          "date": "2024-05-03T12:07:08.000000Z",
          "type": "Sponsorship",
          "code": "",
          "designation": "Louidjivenson Vilsaint",
          "refunded": 0,
          "method": "Credit Card",
          "result": "( Every Month )  Transaction Reference = ch_2PCKtL4bY5qBmxTl0z9cBzph [card last digits 4242 with expiry 12/2031]",
          "amount": "32.00",
          "donor": {
            "id": 26087,
            "hysform_id": 639,
            "name": "Schade",
            "email": "s.charlesderrick9@gmail.com"
          }
        },
        {
          "id": 379465,
          "date": "2024-05-03T12:07:05.000000Z",
          "type": "Sponsorship",
          "code": "",
          "designation": "Wilna Dervil",
          "refunded": 0,
          "method": "Credit Card",
          "result": "( Every Month )  Transaction Reference = ch_2PCKtI4bY5qBmxTl1BaJSAUJ [card last digits 4242 with expiry 2/2025]",
          "amount": "32.00",
          "donor": {
            "id": 17166,
            "hysform_id": 639,
            "name": "John, Katie & Clara Richter CC Monthly",
            "email": "dev+6@helpyousponsor.com"
          }
        },
        {
          "id": 379463,
          "date": "2024-05-03T00:00:00.000000Z",
          "type": "Sponsorship",
          "code": "",
          "designation": "Alvens John Kennley Estil",
          "refunded": 0,
          "method": "Credit Card",
          "result": "<p>tes payment 1</p> (Transaction Reference = ch_2PC8Jl4bY5qBmxTl0oLsADR9)",
          "amount": "32.00",
          "donor": {
            "id": 17135,
            "hysform_id": 639,
            "name": "Dianne Test CC Monthly",
            "email": "dev+5@helpyousponsor.com"
          }
        },
        {
          "id": 379461,
          "date": "2024-05-03T00:00:00.000000Z",
          "type": "Sponsorship",
          "code": "",
          "designation": "Alvens John Kennley Estil",
          "refunded": 0,
          "method": "Credit Card",
          "result": "<p>test donation 1</p> (Transaction Reference = Do Not Charge was selected.)",
          "amount": "10.00",
          "donor": {
            "id": 17135,
            "hysform_id": 639,
            "name": "Dianne Test CC Monthly",
            "email": "dev+5@helpyousponsor.com"
          }
        },
        {
          "id": 379458,
          "date": "2024-05-03T00:00:00.000000Z",
          "type": "Sponsorship",
          "code": "",
          "designation": "Wilna Dervil",
          "refunded": 0,
          "method": "Credit Card",
          "result": "<p>Test 2 Donation</p> (Transaction Reference = Do Not Charge was selected.)",
          "amount": "25.00",
          "donor": {
            "id": 17166,
            "hysform_id": 639,
            "name": "John, Katie & Clara Richter CC Monthly",
            "email": "dev+6@helpyousponsor.com"
          }
        },
        {
          "id": 379456,
          "date": "2024-05-02T12:05:17.000000Z",
          "type": "Sponsorship",
          "code": "",
          "designation": "Kidena\u00efca Sainrilus",
          "refunded": 1,
          "method": "Credit Card",
          "result": "( Every Month )  Transaction Ref = ch_2PByO04bY5qBmxTl1kpN035V",
          "amount": "32.00",
          "donor": {
            "id": 26094,
            "hysform_id": 639,
            "name": "Test Evelyn",
            "email": "evetest@gmail.com"
          }
        },
        {
          "id": 379455,
          "date": "2024-05-02T12:05:09.000000Z",
          "type": "Sponsorship",
          "code": "",
          "designation": "Masaka BoreHole",
          "refunded": 0,
          "method": "Credit Card",
          "result": "( Every Month )  Transaction Reference = ch_2PByNs4bY5qBmxTl0CjNndst [card last digits 4242 with expiry 12/2031]",
          "amount": "105.00",
          "donor": {
            "id": 26087,
            "hysform_id": 639,
            "name": "Schade",
            "email": "s.charlesderrick9@gmail.com"
          }
        },
        {
          "id": 379454,
          "date": "2024-05-02T12:05:06.000000Z",
          "type": "Sponsorship",
          "code": "",
          "designation": "Masaka BoreHole",
          "refunded": 0,
          "method": "Credit Card",
          "result": "( Every Month )  Transaction Reference = ch_2PByNp4bY5qBmxTl0ZxadAwL [card last digits 4242 with expiry 12/2031]",
          "amount": "262.50",
          "donor": {
            "id": 26087,
            "hysform_id": 639,
            "name": "Schade",
            "email": "s.charlesderrick9@gmail.com"
          }
        },
        {
          "id": 379453,
          "date": "2024-05-02T12:05:03.000000Z",
          "type": "Sponsorship",
          "code": "",
          "designation": "Louidjivenson Vilsaint",
          "refunded": 0,
          "method": "Credit Card",
          "result": "( Every Month )  Transaction Reference = ch_2PByNm4bY5qBmxTl16uaWvgn [card last digits 4242 with expiry 12/2031]",
          "amount": "32.00",
          "donor": {
            "id": 26087,
            "hysform_id": 639,
            "name": "Schade",
            "email": "s.charlesderrick9@gmail.com"
          }
        },
        {
          "id": 379452,
          "date": "2024-05-02T12:05:00.000000Z",
          "type": "Sponsorship",
          "code": "",
          "designation": "Wilna Dervil",
          "refunded": 0,
          "method": "Credit Card",
          "result": "( Every Month )  Transaction Reference = ch_2PByNj4bY5qBmxTl00YsEHNx [card last digits 4242 with expiry 2/2025]",
          "amount": "32.00",
          "donor": {
            "id": 17166,
            "hysform_id": 639,
            "name": "John, Katie & Clara Richter CC Monthly",
            "email": "dev+6@helpyousponsor.com"
          }
        },
        {
          "id": 379451,
          "date": "2024-05-02T08:33:00.000000Z",
          "type": "Sponsorship",
          "code": "",
          "designation": "Lovencia Anexil",
          "refunded": 0,
          "method": "Credit Card",
          "result": "(Transaction Reference = ch_2PBv4Z4bY5qBmxTl0dtlsE2u)",
          "amount": "32.00",
          "donor": {
            "id": 26103,
            "hysform_id": 639,
            "name": "Test Leo 10",
            "email": "testleo10@gmail.com"
          }
        },
        {
          "id": 379459,
          "date": "2024-05-02T00:00:00.000000Z",
          "type": "Sponsorship",
          "code": "",
          "designation": "Wilna Dervil",
          "refunded": 0,
          "method": "Credit Card",
          "result": "<p>Test 1 Payment</p> (Transaction Reference = ch_2PC4fO4bY5qBmxTl1c5lU86s)",
          "amount": "32.00",
          "donor": {
            "id": 17166,
            "hysform_id": 639,
            "name": "John, Katie & Clara Richter CC Monthly",
            "email": "dev+6@helpyousponsor.com"
          }
        },
        {
          "id": 379457,
          "date": "2024-05-02T00:00:00.000000Z",
          "type": "Sponsorship",
          "code": "",
          "designation": "Wilna Dervil",
          "refunded": 0,
          "method": "Credit Card",
          "result": "<p>Test 1 Donation</p> (Transaction Reference = ch_2PC4eH4bY5qBmxTl0EGcJEaB)",
          "amount": "20.00",
          "donor": {
            "id": 17166,
            "hysform_id": 639,
            "name": "John, Katie & Clara Richter CC Monthly",
            "email": "dev+6@helpyousponsor.com"
          }
        },
        {
          "id": 379450,
          "date": "2024-05-02T00:00:00.000000Z",
          "type": "Sponsorship",
          "code": "",
          "designation": "Isedervensley Saint-Julles",
          "refunded": 0,
          "method": "Credit Card",
          "result": " (Transaction Reference = ch_2PBsZQ4bY5qBmxTl1HFF9YNZ)",
          "amount": "32.00",
          "donor": {
            "id": 17017,
            "hysform_id": 639,
            "name": "Test Donor Shaban Paypal, monthly",
            "email": "shabanlubanga@gmail.com"
          }
        },
        {
          "id": 379449,
          "date": "2024-05-02T00:00:00.000000Z",
          "type": "Donation",
          "code": "",
          "designation": "Bag of Beans",
          "refunded": 0,
          "method": "Wire Transfer",
          "result": "<p>(Transaction Reference = Do Not Charge was selected.)</p>",
          "amount": "13.00",
          "donor": {
            "id": 26106,
            "hysform_id": "",
            "name": "No Sponsor Name Found",
            "email": ""
          }
        },
        {
          "id": 379460,
          "date": "2024-05-01T00:00:00.000000Z",
          "type": "Sponsorship",
          "code": "",
          "designation": "Wilna Dervil",
          "refunded": 0,
          "method": "Credit Card",
          "result": "<p>sdfsdf</p> (Transaction Reference = ch_2PC4gG4bY5qBmxTl1aqOiMV0)",
          "amount": "32.00",
          "donor": {
            "id": 17166,
            "hysform_id": 639,
            "name": "John, Katie & Clara Richter CC Monthly",
            "email": "dev+6@helpyousponsor.com"
          }
        },
        {
          "id": 379448,
          "date": "2024-04-25T21:17:56.000000Z",
          "type": "Sponsorship",
          "code": "",
          "designation": "Karitchina Barth\u00e9lus",
          "refunded": 0,
          "method": "Credit Card",
          "result": "<p>(Transaction Reference = ch_2P9Zfz4bY5qBmxTl1DUeAKx6)</p>",
          "amount": "38.00",
          "donor": {
            "id": 26103,
            "hysform_id": 639,
            "name": "Test Leo 10",
            "email": "testleo10@gmail.com"
          }
        },
        {
          "id": 379447,
          "date": "2024-04-24T05:14:33.000000Z",
          "type": "Sponsorship",
          "code": "",
          "designation": "Echlander Jean-Baptiste",
          "refunded": 0,
          "method": "Credit Card",
          "result": "(Transaction Reference = ch_2P8yA84bY5qBmxTl06RGLy8G)",
          "amount": "32.00",
          "donor": {
            "id": 26104,
            "hysform_id": 639,
            "name": "testleo12@gmail.com",
            "email": "testleo12@gmail.com"
          }
        },
        {
          "id": 379446,
          "date": "2024-04-24T05:12:33.000000Z",
          "type": "Sponsorship",
          "code": "",
          "designation": "Abednego Augustin",
          "refunded": 0,
          "method": "Credit Card",
          "result": "(Transaction Reference = ch_2P8y8C4bY5qBmxTl0rWJuVQA)",
          "amount": "192.00",
          "donor": {
            "id": 26104,
            "hysform_id": 639,
            "name": "testleo12@gmail.com",
            "email": "testleo12@gmail.com"
          }
        },
        {
          "id": 379445,
          "date": "2024-04-24T05:08:34.000000Z",
          "type": "Sponsorship",
          "code": "",
          "designation": "Wilinder Anexil",
          "refunded": 0,
          "method": "Credit Card",
          "result": "(Transaction Reference = ch_2P8y4K4bY5qBmxTl0IV4rPGo)",
          "amount": "32.00",
          "donor": {
            "id": 26104,
            "hysform_id": 639,
            "name": "testleo12@gmail.com",
            "email": "testleo12@gmail.com"
          }
        },
        {
          "id": 379444,
          "date": "2024-04-24T05:08:33.000000Z",
          "type": "Sponsorship",
          "code": "",
          "designation": "L\u00e9onise Augustin",
          "refunded": 0,
          "method": "Credit Card",
          "result": "(Transaction Reference = ch_2P8y4K4bY5qBmxTl0IV4rPGo)",
          "amount": "32.00",
          "donor": {
            "id": 26104,
            "hysform_id": 639,
            "name": "testleo12@gmail.com",
            "email": "testleo12@gmail.com"
          }
        },
        {
          "id": 379422,
          "date": "2024-04-20T00:00:00.000000Z",
          "type": "Donation",
          "code": "TGT",
          "designation": "Test Gift",
          "refunded": 0,
          "method": "External Processor",
          "result": "<p>(Transaction Reference = Do Not Charge was selected.)</p>",
          "amount": "51.00",
          "donor": {
            "id": 17017,
            "hysform_id": 639,
            "name": "Test Donor Shaban Paypal, monthly",
            "email": "shabanlubanga@gmail.com"
          }
        },
        {
          "id": 379443,
          "date": "2024-04-19T14:40:11.000000Z",
          "type": "Sponsorship",
          "code": "",
          "designation": "Magguedarline Destin",
          "refunded": 0,
          "method": "Credit Card",
          "result": "(Transaction Reference = ch_2P7Ibm4bY5qBmxTl1A7XLyxp)",
          "amount": "32.00",
          "donor": {
            "id": 26103,
            "hysform_id": 639,
            "name": "Test Leo 10",
            "email": "testleo10@gmail.com"
          }
        },
        {
          "id": 379442,
          "date": "2024-04-19T14:34:49.000000Z",
          "type": "Sponsorship",
          "code": "",
          "designation": "Madoch\u00e9 Jean Louis",
          "refunded": 0,
          "method": "Credit Card",
          "result": "(Transaction Reference = ch_2P7IWa4bY5qBmxTl1MYJ8vJP)",
          "amount": "32.00",
          "donor": {
            "id": 26103,
            "hysform_id": 639,
            "name": "Test Leo 10",
            "email": "testleo10@gmail.com"
          }
        },
        {
          "id": 379441,
          "date": "2024-04-19T14:34:49.000000Z",
          "type": "Sponsorship",
          "code": "",
          "designation": "Fritzchardens Hilaire",
          "refunded": 0,
          "method": "Credit Card",
          "result": "(Transaction Reference = ch_2P7IWa4bY5qBmxTl1MYJ8vJP)",
          "amount": "192.00",
          "donor": {
            "id": 26103,
            "hysform_id": 639,
            "name": "Test Leo 10",
            "email": "testleo10@gmail.com"
          }
        },
        {
          "id": 379440,
          "date": "2024-04-19T10:52:27.000000Z",
          "type": "Sponsorship",
          "code": "",
          "designation": "Wensenley Fransky Racine",
          "refunded": 0,
          "method": "Credit Card",
          "result": "(Transaction Reference = ch_2P7F3O4bY5qBmxTl1QoEYDQf)",
          "amount": "32.00",
          "donor": {
            "id": 26103,
            "hysform_id": 639,
            "name": "Test Leo 10",
            "email": "testleo10@gmail.com"
          }
        },
        {
          "id": 379439,
          "date": "2024-04-19T10:49:18.000000Z",
          "type": "Sponsorship",
          "code": "",
          "designation": "Shyna\u00efdine Bien-Aim\u00e9",
          "refunded": 0,
          "method": "Credit Card",
          "result": "(Transaction Reference = ch_2P7F0L4bY5qBmxTl1sdrNc1l)",
          "amount": "32.00",
          "donor": {
            "id": 26103,
            "hysform_id": 639,
            "name": "Test Leo 10",
            "email": "testleo10@gmail.com"
          }
        },
        {
          "id": 379438,
          "date": "2024-04-19T06:57:37.000000Z",
          "type": "Sponsorship",
          "code": "",
          "designation": " Wolf Nashimpson Pierre",
          "refunded": 0,
          "method": "Credit Card",
          "result": "(Transaction Reference = ch_2P7BO84bY5qBmxTl0wXLDKRU)",
          "amount": "32.00",
          "donor": {
            "id": 26103,
            "hysform_id": 639,
            "name": "Test Leo 10",
            "email": "testleo10@gmail.com"
          }
        },
        {
          "id": 379437,
          "date": "2024-04-18T05:55:32.000000Z",
          "type": "Sponsorship",
          "code": "",
          "designation": "Djeff Pierre",
          "refunded": 0,
          "method": "Credit Card",
          "result": "(Transaction Reference = ch_2P6nwV4bY5qBmxTl131BXzUT)",
          "amount": "32.00",
          "donor": {
            "id": 26103,
            "hysform_id": 639,
            "name": "Test Leo 10",
            "email": "testleo10@gmail.com"
          }
        },
        {
          "id": 379436,
          "date": "2024-04-18T05:54:05.000000Z",
          "type": "Sponsorship",
          "code": "",
          "designation": "Marckendet No\u00ebl",
          "refunded": 0,
          "method": "Credit Card",
          "result": "(Transaction Reference = ch_2P6nv64bY5qBmxTl1sF0Xp2J)",
          "amount": "96.00",
          "donor": {
            "id": 26103,
            "hysform_id": 639,
            "name": "Test Leo 10",
            "email": "testleo10@gmail.com"
          }
        },
        {
          "id": 379435,
          "date": "2024-04-18T05:54:05.000000Z",
          "type": "Sponsorship",
          "code": "",
          "designation": "Shena\u00efder Saint-Vil",
          "refunded": 0,
          "method": "Credit Card",
          "result": "(Transaction Reference = ch_2P6nv64bY5qBmxTl1sF0Xp2J)",
          "amount": "32.00",
          "donor": {
            "id": 26103,
            "hysform_id": 639,
            "name": "Test Leo 10",
            "email": "testleo10@gmail.com"
          }
        }
    ]
}

export const donationsData = {
  "donations": [
      {
        "donation_id": 379539,
        "profile": {
          "dn_donor_name": "Test Mulimwa",
          "client_id": 207,
          "hysform_id": 639,
          "username": "TestMulimwa@gmail.com",
          "email": "TestMulimwa@gmail.com"
        },
        "donor": {
          "id": 26134,
          "hysform_id": 639,
          "name": "Test Mulimwa",
          "email": "TestMulimwa@gmail.com"
        },
        "designation": {
          "name": "Kelluckson Jack",
          "type": 1,
          "id": 36305,
          "emailset_id": 337,
          "program_name": "Sponsored",
          "program_id": 1221
        },
        "method": "Cash",
        "amount": "13.00",
        "refunded": 0,
        "refunded_amount": "0.00",
        "date": "2024-07-09T00:00:00.000000Z",
        "result": null
      },
      {
        "donation_id": 379538,
        "profile": {
          "id": 26133,
          "client_id": 207,
          "hysform_id": 639,
          "do_not_email": 0,
          "wait_time": null,
          "who_added": "{\"type\":\"donor\",\"method\":\"individual\",\"id\":\"\"}",
          "stripe_cust_id": null,
          "authorize_profile": null,
          "json_fields": "{\"dn_donor_name\":\"Test Sponsor\"}",
          "deleted_at": null,
          "created_at": "2024-07-09T13:36:39.000000Z",
          "updated_at": "2024-07-09T13:36:40.000000Z",
          "last_login": "2024-07-09 13:36:40",
          "__hevo__database_name": null,
          "__hevo__ingested_at": null,
          "__hevo__marked_deleted": null,
          "__hevo__source_modified_at": null,
          "stripe_card_id": null,
          "paypal_email": null,
          "dn_donor_name": "Test Sponsor",
          "donor_id": 26133,
          "username": "SponsorTest@gmail.com",
          "email": "SponsorTest@gmail.com"
        },
        "donor": {
          "id": 26133,
          "hysform_id": 639,
          "name": "Test Sponsor",
          "email": "SponsorTest@gmail.com"
        },
        "designation": {
          "code": "",
          "name": "Bag of Rice",
          "type": 2,
          "id": 190,
          "emailset_id": 337,
          "program_name": ""
        },
        "method": "Check",
        "amount": "12.00",
        "refunded": 0,
        "refunded_amount": "0.00",
        "date": "2024-07-09T00:00:00.000000Z",
        "result": null
      },
      {
        "donation_id": 379537,
        "profile": {
          "id": 17017,
          "client_id": 207,
          "hysform_id": 639,
          "do_not_email": 0,
          "wait_time": null,
          "who_added": "{\"type\":\"donor\",\"method\":\"individual\",\"id\":\"\"}",
          "stripe_cust_id": "cus_Pu5GLVi5fMVq1x",
          "authorize_profile": null,
          "json_fields": "{\"dn_donor_name\":\"Test Donor Shaban\",\"dn_payment_type\":\"Paypal, monthly\",\"dn_child_sponsored\":\"Isedervensley Saint-Julles &  Peterson Jean\",\"fakeusernameremembered\":\"\",\"fakepasswordremembered\":\"\"}",
          "deleted_at": null,
          "created_at": "2018-09-20T23:36:24.000000Z",
          "updated_at": "2024-05-08T19:23:47.000000Z",
          "last_login": "2023-01-31 09:50:48",
          "__hevo__database_name": "apphys_new",
          "__hevo__ingested_at": 1679725257574,
          "__hevo__marked_deleted": 0,
          "__hevo__source_modified_at": null,
          "stripe_card_id": "card_0P4H5y4bY5qBmxTlNghUsBPu",
          "paypal_email": "shabanlubanga@gmail.com",
          "dn_donor_name": "Test Donor Shaban",
          "dn_payment_type": "Paypal, monthly",
          "dn_child_sponsored": "Isedervensley Saint-Julles &  Peterson Jean",
          "fakeusernameremembered": null,
          "fakepasswordremembered": null,
          "password": "1234567",
          "donor_id": "17017",
          "username": "shabanlubanga@gmail.com",
          "email": "shabanlubanga@gmail.com"
        },
        "donor": {
          "id": 17017,
          "hysform_id": 639,
          "name": "Test Donor Shaban Paypal, monthly",
          "email": "shabanlubanga@gmail.com"
        },
        "designation": {
          "name": "Estelande Alfred",
          "type": 1,
          "id": 36308,
          "emailset_id": 337,
          "program_name": "Education",
          "program_id": 929
        },
        "method": "Credit Card",
        "amount": "32.00",
        "refunded": 0,
        "refunded_amount": "0.00",
        "date": "2024-06-26T10:51:42.000000Z",
        "result": null
      },
      {
        "donation_id": 379536,
        "profile": {
          "id": 17017,
          "client_id": 207,
          "hysform_id": 639,
          "do_not_email": 0,
          "wait_time": null,
          "who_added": "{\"type\":\"donor\",\"method\":\"individual\",\"id\":\"\"}",
          "stripe_cust_id": "cus_Pu5GLVi5fMVq1x",
          "authorize_profile": null,
          "json_fields": "{\"dn_donor_name\":\"Test Donor Shaban\",\"dn_payment_type\":\"Paypal, monthly\",\"dn_child_sponsored\":\"Isedervensley Saint-Julles &  Peterson Jean\",\"fakeusernameremembered\":\"\",\"fakepasswordremembered\":\"\"}",
          "deleted_at": null,
          "created_at": "2018-09-20T23:36:24.000000Z",
          "updated_at": "2024-05-08T19:23:47.000000Z",
          "last_login": "2023-01-31 09:50:48",
          "__hevo__database_name": "apphys_new",
          "__hevo__ingested_at": 1679725257574,
          "__hevo__marked_deleted": 0,
          "__hevo__source_modified_at": null,
          "stripe_card_id": "card_0P4H5y4bY5qBmxTlNghUsBPu",
          "paypal_email": "shabanlubanga@gmail.com",
          "dn_donor_name": "Test Donor Shaban",
          "dn_payment_type": "Paypal, monthly",
          "dn_child_sponsored": "Isedervensley Saint-Julles &  Peterson Jean",
          "fakeusernameremembered": null,
          "fakepasswordremembered": null,
          "password": "1234567",
          "donor_id": "17017",
          "username": "shabanlubanga@gmail.com",
          "email": "shabanlubanga@gmail.com"
        },
        "donor": {
          "id": 17017,
          "hysform_id": 639,
          "name": "Test Donor Shaban Paypal, monthly",
          "email": "shabanlubanga@gmail.com"
        },
        "designation": {
          "name": "Estelande Alfred",
          "type": 1,
          "id": 36308,
          "emailset_id": 337,
          "program_name": "Education",
          "program_id": 929
        },
        "method": "Credit Card",
        "amount": "32.00",
        "refunded": 0,
        "refunded_amount": "0.00",
        "date": "2024-06-26T10:36:48.000000Z",
        "result": null
      },
      {
        "donation_id": 379535,
        "profile": {
          "id": 17017,
          "client_id": 207,
          "hysform_id": 639,
          "do_not_email": 0,
          "wait_time": null,
          "who_added": "{\"type\":\"donor\",\"method\":\"individual\",\"id\":\"\"}",
          "stripe_cust_id": "cus_Pu5GLVi5fMVq1x",
          "authorize_profile": null,
          "json_fields": "{\"dn_donor_name\":\"Test Donor Shaban\",\"dn_payment_type\":\"Paypal, monthly\",\"dn_child_sponsored\":\"Isedervensley Saint-Julles &  Peterson Jean\",\"fakeusernameremembered\":\"\",\"fakepasswordremembered\":\"\"}",
          "deleted_at": null,
          "created_at": "2018-09-20T23:36:24.000000Z",
          "updated_at": "2024-05-08T19:23:47.000000Z",
          "last_login": "2023-01-31 09:50:48",
          "__hevo__database_name": "apphys_new",
          "__hevo__ingested_at": 1679725257574,
          "__hevo__marked_deleted": 0,
          "__hevo__source_modified_at": null,
          "stripe_card_id": "card_0P4H5y4bY5qBmxTlNghUsBPu",
          "paypal_email": "shabanlubanga@gmail.com",
          "dn_donor_name": "Test Donor Shaban",
          "dn_payment_type": "Paypal, monthly",
          "dn_child_sponsored": "Isedervensley Saint-Julles &  Peterson Jean",
          "fakeusernameremembered": null,
          "fakepasswordremembered": null,
          "password": "1234567",
          "donor_id": "17017",
          "username": "shabanlubanga@gmail.com",
          "email": "shabanlubanga@gmail.com"
        },
        "donor": {
          "id": 17017,
          "hysform_id": 639,
          "name": "Test Donor Shaban Paypal, monthly",
          "email": "shabanlubanga@gmail.com"
        },
        "designation": {
          "name": "Estelande Alfred",
          "type": 1,
          "id": 36308,
          "emailset_id": 337,
          "program_name": "Education",
          "program_id": 929
        },
        "method": "Credit Card",
        "amount": "32.00",
        "refunded": 0,
        "refunded_amount": "0.00",
        "date": "2024-06-26T10:35:45.000000Z",
        "result": null
      },
      {
        "donation_id": 379534,
        "profile": {
          "id": 17017,
          "client_id": 207,
          "hysform_id": 639,
          "do_not_email": 0,
          "wait_time": null,
          "who_added": "{\"type\":\"donor\",\"method\":\"individual\",\"id\":\"\"}",
          "stripe_cust_id": "cus_Pu5GLVi5fMVq1x",
          "authorize_profile": null,
          "json_fields": "{\"dn_donor_name\":\"Test Donor Shaban\",\"dn_payment_type\":\"Paypal, monthly\",\"dn_child_sponsored\":\"Isedervensley Saint-Julles &  Peterson Jean\",\"fakeusernameremembered\":\"\",\"fakepasswordremembered\":\"\"}",
          "deleted_at": null,
          "created_at": "2018-09-20T23:36:24.000000Z",
          "updated_at": "2024-05-08T19:23:47.000000Z",
          "last_login": "2023-01-31 09:50:48",
          "__hevo__database_name": "apphys_new",
          "__hevo__ingested_at": 1679725257574,
          "__hevo__marked_deleted": 0,
          "__hevo__source_modified_at": null,
          "stripe_card_id": "card_0P4H5y4bY5qBmxTlNghUsBPu",
          "paypal_email": "shabanlubanga@gmail.com",
          "dn_donor_name": "Test Donor Shaban",
          "dn_payment_type": "Paypal, monthly",
          "dn_child_sponsored": "Isedervensley Saint-Julles &  Peterson Jean",
          "fakeusernameremembered": null,
          "fakepasswordremembered": null,
          "password": "1234567",
          "donor_id": "17017",
          "username": "shabanlubanga@gmail.com",
          "email": "shabanlubanga@gmail.com"
        },
        "donor": {
          "id": 17017,
          "hysform_id": 639,
          "name": "Test Donor Shaban Paypal, monthly",
          "email": "shabanlubanga@gmail.com"
        },
        "designation": {
          "name": "Estelande Alfred",
          "type": 1,
          "id": 36308,
          "emailset_id": 337,
          "program_name": "Education",
          "program_id": 929
        },
        "method": "Credit Card",
        "amount": "32.00",
        "refunded": 0,
        "refunded_amount": "0.00",
        "date": "2024-06-26T10:35:04.000000Z",
        "result": null
      },
      {
        "donation_id": 379533,
        "profile": {
          "id": 17017,
          "client_id": 207,
          "hysform_id": 639,
          "do_not_email": 0,
          "wait_time": null,
          "who_added": "{\"type\":\"donor\",\"method\":\"individual\",\"id\":\"\"}",
          "stripe_cust_id": "cus_Pu5GLVi5fMVq1x",
          "authorize_profile": null,
          "json_fields": "{\"dn_donor_name\":\"Test Donor Shaban\",\"dn_payment_type\":\"Paypal, monthly\",\"dn_child_sponsored\":\"Isedervensley Saint-Julles &  Peterson Jean\",\"fakeusernameremembered\":\"\",\"fakepasswordremembered\":\"\"}",
          "deleted_at": null,
          "created_at": "2018-09-20T23:36:24.000000Z",
          "updated_at": "2024-05-08T19:23:47.000000Z",
          "last_login": "2023-01-31 09:50:48",
          "__hevo__database_name": "apphys_new",
          "__hevo__ingested_at": 1679725257574,
          "__hevo__marked_deleted": 0,
          "__hevo__source_modified_at": null,
          "stripe_card_id": "card_0P4H5y4bY5qBmxTlNghUsBPu",
          "paypal_email": "shabanlubanga@gmail.com",
          "dn_donor_name": "Test Donor Shaban",
          "dn_payment_type": "Paypal, monthly",
          "dn_child_sponsored": "Isedervensley Saint-Julles &  Peterson Jean",
          "fakeusernameremembered": null,
          "fakepasswordremembered": null,
          "password": "1234567",
          "donor_id": "17017",
          "username": "shabanlubanga@gmail.com",
          "email": "shabanlubanga@gmail.com"
        },
        "donor": {
          "id": 17017,
          "hysform_id": 639,
          "name": "Test Donor Shaban Paypal, monthly",
          "email": "shabanlubanga@gmail.com"
        },
        "designation": {
          "name": "Estelande Alfred",
          "type": 1,
          "id": 36308,
          "emailset_id": 337,
          "program_name": "Education",
          "program_id": 929
        },
        "method": "Credit Card",
        "amount": "32.00",
        "refunded": 0,
        "refunded_amount": "0.00",
        "date": "2024-06-26T10:05:14.000000Z",
        "result": null
      },
      {
        "donation_id": 379532,
        "profile": {
          "id": 17017,
          "client_id": 207,
          "hysform_id": 639,
          "do_not_email": 0,
          "wait_time": null,
          "who_added": "{\"type\":\"donor\",\"method\":\"individual\",\"id\":\"\"}",
          "stripe_cust_id": "cus_Pu5GLVi5fMVq1x",
          "authorize_profile": null,
          "json_fields": "{\"dn_donor_name\":\"Test Donor Shaban\",\"dn_payment_type\":\"Paypal, monthly\",\"dn_child_sponsored\":\"Isedervensley Saint-Julles &  Peterson Jean\",\"fakeusernameremembered\":\"\",\"fakepasswordremembered\":\"\"}",
          "deleted_at": null,
          "created_at": "2018-09-20T23:36:24.000000Z",
          "updated_at": "2024-05-08T19:23:47.000000Z",
          "last_login": "2023-01-31 09:50:48",
          "__hevo__database_name": "apphys_new",
          "__hevo__ingested_at": 1679725257574,
          "__hevo__marked_deleted": 0,
          "__hevo__source_modified_at": null,
          "stripe_card_id": "card_0P4H5y4bY5qBmxTlNghUsBPu",
          "paypal_email": "shabanlubanga@gmail.com",
          "dn_donor_name": "Test Donor Shaban",
          "dn_payment_type": "Paypal, monthly",
          "dn_child_sponsored": "Isedervensley Saint-Julles &  Peterson Jean",
          "fakeusernameremembered": null,
          "fakepasswordremembered": null,
          "password": "1234567",
          "donor_id": "17017",
          "username": "shabanlubanga@gmail.com",
          "email": "shabanlubanga@gmail.com"
        },
        "donor": {
          "id": 17017,
          "hysform_id": 639,
          "name": "Test Donor Shaban Paypal, monthly",
          "email": "shabanlubanga@gmail.com"
        },
        "designation": {
          "name": "Estelande Alfred",
          "type": 1,
          "id": 36308,
          "emailset_id": 337,
          "program_name": "Education",
          "program_id": 929
        },
        "method": "Credit Card",
        "amount": "32.00",
        "refunded": 0,
        "refunded_amount": "0.00",
        "date": "2024-06-26T10:04:17.000000Z",
        "result": null
      },
      {
        "donation_id": 379531,
        "profile": {
          "id": 17017,
          "client_id": 207,
          "hysform_id": 639,
          "do_not_email": 0,
          "wait_time": null,
          "who_added": "{\"type\":\"donor\",\"method\":\"individual\",\"id\":\"\"}",
          "stripe_cust_id": "cus_Pu5GLVi5fMVq1x",
          "authorize_profile": null,
          "json_fields": "{\"dn_donor_name\":\"Test Donor Shaban\",\"dn_payment_type\":\"Paypal, monthly\",\"dn_child_sponsored\":\"Isedervensley Saint-Julles &  Peterson Jean\",\"fakeusernameremembered\":\"\",\"fakepasswordremembered\":\"\"}",
          "deleted_at": null,
          "created_at": "2018-09-20T23:36:24.000000Z",
          "updated_at": "2024-05-08T19:23:47.000000Z",
          "last_login": "2023-01-31 09:50:48",
          "__hevo__database_name": "apphys_new",
          "__hevo__ingested_at": 1679725257574,
          "__hevo__marked_deleted": 0,
          "__hevo__source_modified_at": null,
          "stripe_card_id": "card_0P4H5y4bY5qBmxTlNghUsBPu",
          "paypal_email": "shabanlubanga@gmail.com",
          "dn_donor_name": "Test Donor Shaban",
          "dn_payment_type": "Paypal, monthly",
          "dn_child_sponsored": "Isedervensley Saint-Julles &  Peterson Jean",
          "fakeusernameremembered": null,
          "fakepasswordremembered": null,
          "password": "1234567",
          "donor_id": "17017",
          "username": "shabanlubanga@gmail.com",
          "email": "shabanlubanga@gmail.com"
        },
        "donor": {
          "id": 17017,
          "hysform_id": 639,
          "name": "Test Donor Shaban Paypal, monthly",
          "email": "shabanlubanga@gmail.com"
        },
        "designation": {
          "name": "Estelande Alfred",
          "type": 1,
          "id": 36308,
          "emailset_id": 337,
          "program_name": "Education",
          "program_id": 929
        },
        "method": "Credit Card",
        "amount": "32.00",
        "refunded": 0,
        "refunded_amount": "0.00",
        "date": "2024-06-26T10:03:46.000000Z",
        "result": null
      },
      {
        "donation_id": 379530,
        "profile": {
          "id": 17017,
          "client_id": 207,
          "hysform_id": 639,
          "do_not_email": 0,
          "wait_time": null,
          "who_added": "{\"type\":\"donor\",\"method\":\"individual\",\"id\":\"\"}",
          "stripe_cust_id": "cus_Pu5GLVi5fMVq1x",
          "authorize_profile": null,
          "json_fields": "{\"dn_donor_name\":\"Test Donor Shaban\",\"dn_payment_type\":\"Paypal, monthly\",\"dn_child_sponsored\":\"Isedervensley Saint-Julles &  Peterson Jean\",\"fakeusernameremembered\":\"\",\"fakepasswordremembered\":\"\"}",
          "deleted_at": null,
          "created_at": "2018-09-20T23:36:24.000000Z",
          "updated_at": "2024-05-08T19:23:47.000000Z",
          "last_login": "2023-01-31 09:50:48",
          "__hevo__database_name": "apphys_new",
          "__hevo__ingested_at": 1679725257574,
          "__hevo__marked_deleted": 0,
          "__hevo__source_modified_at": null,
          "stripe_card_id": "card_0P4H5y4bY5qBmxTlNghUsBPu",
          "paypal_email": "shabanlubanga@gmail.com",
          "dn_donor_name": "Test Donor Shaban",
          "dn_payment_type": "Paypal, monthly",
          "dn_child_sponsored": "Isedervensley Saint-Julles &  Peterson Jean",
          "fakeusernameremembered": null,
          "fakepasswordremembered": null,
          "password": "1234567",
          "donor_id": "17017",
          "username": "shabanlubanga@gmail.com",
          "email": "shabanlubanga@gmail.com"
        },
        "donor": {
          "id": 17017,
          "hysform_id": 639,
          "name": "Test Donor Shaban Paypal, monthly",
          "email": "shabanlubanga@gmail.com"
        },
        "designation": {
          "name": "Estelande Alfred",
          "type": 1,
          "id": 36308,
          "emailset_id": 337,
          "program_name": "Education",
          "program_id": 929
        },
        "method": "Credit Card",
        "amount": "32.00",
        "refunded": 0,
        "refunded_amount": "0.00",
        "date": "2024-06-26T09:56:41.000000Z",
        "result": null
      }
    ],
    "organization": "Giving Hands Ministry",
    "number_of_allDonations": 19,
    "number_of_donations": 10,
    "total_of_donations": "560",
    "donation_graph_data": [
      {
        "dates": "2024-06-18",
        "totals": "0"
      },
      {
        "dates": "2024-06-19",
        "totals": "0"
      },
      {
        "dates": "2024-06-20",
        "totals": "0"
      },
      {
        "dates": "2024-06-21",
        "totals": "0"
      },
      {
        "dates": "2024-06-22",
        "totals": "0"
      },
      {
        "dates": "2024-06-23",
        "totals": "0"
      },
      {
        "dates": "2024-06-24",
        "totals": "215"
      },
      {
        "dates": "2024-06-25",
        "totals": "0"
      },
      {
        "dates": "2024-06-26",
        "totals": "320"
      },
      {
        "dates": "2024-06-27",
        "totals": "0"
      },
      {
        "dates": "2024-06-28",
        "totals": "0"
      },
      {
        "dates": "2024-06-29",
        "totals": "0"
      },
      {
        "dates": "2024-06-30",
        "totals": "0"
      },
      {
        "dates": "2024-07-01",
        "totals": "0"
      },
      {
        "dates": "2024-07-02",
        "totals": "0"
      },
      {
        "dates": "2024-07-03",
        "totals": "0"
      },
      {
        "dates": "2024-07-04",
        "totals": "0"
      },
      {
        "dates": "2024-07-05",
        "totals": "0"
      },
      {
        "dates": "2024-07-06",
        "totals": "0"
      },
      {
        "dates": "2024-07-07",
        "totals": "0"
      },
      {
        "dates": "2024-07-08",
        "totals": "0"
      },
      {
        "dates": "2024-07-09",
        "totals": "25"
      },
      {
        "dates": "2024-07-10",
        "totals": "0"
      },
      {
        "dates": "2024-07-11",
        "totals": "0"
      },
      {
        "dates": "2024-07-12",
        "totals": "0"
      },
      {
        "dates": "2024-07-13",
        "totals": "0"
      },
      {
        "dates": "2024-07-14",
        "totals": "0"
      },
      {
        "dates": "2024-07-15",
        "totals": "0"
      },
      {
        "dates": "2024-07-16",
        "totals": "0"
      },
      {
        "dates": "2024-07-17",
        "totals": "0"
      },
      {
        "dates": "2024-07-18",
        "totals": "0"
      }
    ],
    "fields": [],
    "date_from": "2024-06-18",
    "date_to": "2024-07-18",
    "all": "1",
    "hysform": {
      "id": "all",
      "name": "All Donations"
    }
}