export const progressReportsData = {
    "hysforms": [],
    "programs": [
        {
        "id": 928,
        "client_id": 207,
        "hysform_id": 0,
        "donor_hysform_id": 0,
        "setting_id": 0,
        "emailset_id": 0,
        "entity_submit": "",
        "donor_submit": "",
        "link_id": 0,
        "name": "Mission Starfish Haiti",
        "prefix": "",
        "counter": 0,
        "lft": 1,
        "rgt": 22,
        "tree": 207,
        "box_folder_id": "",
        "deleted_at": null,
        "created_at": "2017-02-23T01:40:21.000000Z",
        "updated_at": "2023-03-27T16:37:29.000000Z",
        "__hevo__database_name": "apphys_new",
        "__hevo__ingested_at": 1679910350683,
        "__hevo__marked_deleted": 0,
        "__hevo__source_modified_at": 1679909849000,
        "children": []
        },
        {
        "id": 929,
        "client_id": 207,
        "hysform_id": 638,
        "donor_hysform_id": 639,
        "setting_id": 410,
        "emailset_id": 337,
        "entity_submit": "",
        "donor_submit": "0",
        "link_id": 0,
        "name": "Education",
        "prefix": "",
        "counter": 427,
        "lft": 20,
        "rgt": 21,
        "tree": 207,
        "box_folder_id": "",
        "deleted_at": null,
        "created_at": "2017-02-23T01:40:22.000000Z",
        "updated_at": "2024-06-17T13:15:33.000000Z",
        "__hevo__database_name": "apphys_new",
        "__hevo__ingested_at": 1680004248741,
        "__hevo__marked_deleted": 0,
        "__hevo__source_modified_at": 1680003822000,
        "children": []
        },
        {
        "id": 1221,
        "client_id": 207,
        "hysform_id": 638,
        "donor_hysform_id": 639,
        "setting_id": 410,
        "emailset_id": 337,
        "entity_submit": "",
        "donor_submit": "",
        "link_id": 0,
        "name": "Sponsored",
        "prefix": "",
        "counter": 54,
        "lft": 18,
        "rgt": 19,
        "tree": 207,
        "box_folder_id": "",
        "deleted_at": null,
        "created_at": "2019-01-22T01:50:21.000000Z",
        "updated_at": "2024-07-09T13:54:44.000000Z",
        "__hevo__database_name": "apphys_new",
        "__hevo__ingested_at": 1679910350681,
        "__hevo__marked_deleted": 0,
        "__hevo__source_modified_at": 1679909849000,
        "children": []
        },
        {
        "id": 3437,
        "client_id": 207,
        "hysform_id": 638,
        "donor_hysform_id": 0,
        "setting_id": 410,
        "emailset_id": 0,
        "entity_submit": "",
        "donor_submit": "",
        "link_id": 0,
        "name": "Medical",
        "prefix": "",
        "counter": 0,
        "lft": 16,
        "rgt": 17,
        "tree": 207,
        "box_folder_id": "",
        "deleted_at": null,
        "created_at": "2022-12-14T20:13:03.000000Z",
        "updated_at": "2023-03-28T18:44:07.000000Z",
        "__hevo__database_name": "apphys_new",
        "__hevo__ingested_at": 1680004248746,
        "__hevo__marked_deleted": 0,
        "__hevo__source_modified_at": 1680003847000,
        "children": []
        },
        {
        "id": 3438,
        "client_id": 207,
        "hysform_id": 3019,
        "donor_hysform_id": 639,
        "setting_id": 1641,
        "emailset_id": 0,
        "entity_submit": "",
        "donor_submit": "",
        "link_id": 0,
        "name": "test 4",
        "prefix": "",
        "counter": 0,
        "lft": 14,
        "rgt": 15,
        "tree": 207,
        "box_folder_id": "",
        "deleted_at": null,
        "created_at": "2022-12-21T16:50:33.000000Z",
        "updated_at": "2024-06-25T06:11:37.000000Z",
        "__hevo__database_name": "apphys_new",
        "__hevo__ingested_at": 1679910350683,
        "__hevo__marked_deleted": 0,
        "__hevo__source_modified_at": 1679909849000,
        "children": []
        },
        {
        "id": 3494,
        "client_id": 207,
        "hysform_id": 638,
        "donor_hysform_id": 639,
        "setting_id": 410,
        "emailset_id": 337,
        "entity_submit": null,
        "donor_submit": null,
        "link_id": 929,
        "name": "SubTest2",
        "prefix": "",
        "counter": 1,
        "lft": 21,
        "rgt": 22,
        "tree": 207,
        "box_folder_id": null,
        "deleted_at": null,
        "created_at": "2023-03-25T09:14:29.000000Z",
        "updated_at": "2024-07-09T13:35:33.000000Z",
        "__hevo__database_name": null,
        "__hevo__ingested_at": null,
        "__hevo__marked_deleted": null,
        "__hevo__source_modified_at": null,
        "children": []
        },
        {
        "id": 3506,
        "client_id": 207,
        "hysform_id": 3039,
        "donor_hysform_id": 639,
        "setting_id": 410,
        "emailset_id": 1557,
        "entity_submit": null,
        "donor_submit": null,
        "link_id": null,
        "name": "Kupenda",
        "prefix": "KP",
        "counter": 200,
        "lft": 12,
        "rgt": 13,
        "tree": 207,
        "box_folder_id": null,
        "deleted_at": null,
        "created_at": "2023-04-29T15:08:37.000000Z",
        "updated_at": "2024-07-09T13:33:24.000000Z",
        "__hevo__database_name": null,
        "__hevo__ingested_at": null,
        "__hevo__marked_deleted": null,
        "__hevo__source_modified_at": null,
        "children": []
        },
        {
        "id": 3507,
        "client_id": 207,
        "hysform_id": 3019,
        "donor_hysform_id": 3040,
        "setting_id": 1641,
        "emailset_id": null,
        "entity_submit": null,
        "donor_submit": null,
        "link_id": null,
        "name": "Kupe",
        "prefix": "KUP",
        "counter": null,
        "lft": 10,
        "rgt": 11,
        "tree": 207,
        "box_folder_id": null,
        "deleted_at": null,
        "created_at": "2023-05-03T06:41:45.000000Z",
        "updated_at": "2024-06-21T11:08:10.000000Z",
        "__hevo__database_name": null,
        "__hevo__ingested_at": null,
        "__hevo__marked_deleted": null,
        "__hevo__source_modified_at": null,
        "children": []
        },
        {
        "id": 3510,
        "client_id": 207,
        "hysform_id": 3043,
        "donor_hysform_id": null,
        "setting_id": 410,
        "emailset_id": null,
        "entity_submit": null,
        "donor_submit": null,
        "link_id": null,
        "name": "us2uganda",
        "prefix": "usa",
        "counter": null,
        "lft": 8,
        "rgt": 9,
        "tree": 207,
        "box_folder_id": null,
        "deleted_at": null,
        "created_at": "2023-06-02T09:24:28.000000Z",
        "updated_at": "2023-06-02T09:36:45.000000Z",
        "__hevo__database_name": null,
        "__hevo__ingested_at": null,
        "__hevo__marked_deleted": null,
        "__hevo__source_modified_at": null,
        "children": []
        },
        {
        "id": 3517,
        "client_id": 207,
        "hysform_id": null,
        "donor_hysform_id": null,
        "setting_id": null,
        "emailset_id": null,
        "entity_submit": null,
        "donor_submit": null,
        "link_id": null,
        "name": "hellochild",
        "prefix": "hc",
        "counter": null,
        "lft": 6,
        "rgt": 7,
        "tree": 207,
        "box_folder_id": null,
        "deleted_at": null,
        "created_at": "2023-07-11T11:00:56.000000Z",
        "updated_at": "2023-07-11T11:00:56.000000Z",
        "__hevo__database_name": null,
        "__hevo__ingested_at": null,
        "__hevo__marked_deleted": null,
        "__hevo__source_modified_at": null,
        "children": []
        },
        {
        "id": 3518,
        "client_id": 207,
        "hysform_id": 3048,
        "donor_hysform_id": 639,
        "setting_id": 1650,
        "emailset_id": null,
        "entity_submit": null,
        "donor_submit": null,
        "link_id": null,
        "name": "Masaka BoreHole",
        "prefix": "MBH",
        "counter": 1,
        "lft": 4,
        "rgt": 5,
        "tree": 207,
        "box_folder_id": null,
        "deleted_at": null,
        "created_at": "2023-10-17T06:26:48.000000Z",
        "updated_at": "2024-06-17T12:10:38.000000Z",
        "__hevo__database_name": null,
        "__hevo__ingested_at": null,
        "__hevo__marked_deleted": null,
        "__hevo__source_modified_at": null,
        "children": []
        },
        {
        "id": 3521,
        "client_id": 207,
        "hysform_id": 3051,
        "donor_hysform_id": 639,
        "setting_id": 1652,
        "emailset_id": null,
        "entity_submit": null,
        "donor_submit": null,
        "link_id": null,
        "name": "Demo Campaign",
        "prefix": "DC",
        "counter": 1,
        "lft": 2,
        "rgt": 3,
        "tree": 207,
        "box_folder_id": null,
        "deleted_at": null,
        "created_at": "2024-06-13T08:35:10.000000Z",
        "updated_at": "2024-06-13T08:40:15.000000Z",
        "__hevo__database_name": null,
        "__hevo__ingested_at": null,
        "__hevo__marked_deleted": null,
        "__hevo__source_modified_at": null,
        "children": []
        }
    ],
    "organization": "Giving Hands Ministry"
}