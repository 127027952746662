
  import { Server, Response } from 'miragejs'
  import { progressReportsData } from '../data/archivedreportsData'
  
  export default function archivedreportsFakeApi(server: Server, apiPrefix: string) {
  
    server.get(`${apiPrefix}/admin/progress_report`, (schema, request) => {
      return new Response(200, {}, progressReportsData)
    })
  
    server.post(`${apiPrefix}/admin/progress_report`, (schema, request) => {
      return new Response(200, {}, progressReportsData)
    })
  }