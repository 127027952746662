import { env } from '@/configs/env.config'

export type AppConfig = {
    apiHYS: string
    apiPrefix: string
    authenticatedEntryPath: string
    unAuthenticatedEntryPath: string
    frontendPath: string
    tourPath: string
    locale: string
    enableMock: boolean
}

const appConfig: AppConfig = {
    authenticatedEntryPath: "/app/admin",
    apiHYS: env?.API_ENDPOINT_URL + "/v1",
    //apiPrefix: env?.API_ENDPOINT_URL + "/v1",
    apiPrefix: '/api',
    frontendPath: "/frontend",
    unAuthenticatedEntryPath: '/sign-in',
    tourPath: '/app/account/kyc-form',
    locale: 'en',
    enableMock: true,
}

export default appConfig
