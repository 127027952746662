
  import { Server, Response } from 'miragejs'
  import { donor_activities, files } from '../data/donorData'
  
  export default function programsFakeApi(server: Server, apiPrefix: string) {
  
    server.get(`${apiPrefix}/programs`, (schema, request) => {
      const programs = schema.db.programs
      const archived = schema.db.archived

      const result = {
        programs: programs,
        archived: archived,
        number_of_programs: programs.length,
      }
      return new Response(200, {}, result)
    })

    server.get(`${apiPrefix}/files/:type/:id/table`, (schema, request) => {
      const donor_id = parseInt(request.params.id)
      const type = request.params.type

      if (type === "donor") {
        return new Response(200, {}, files[donor_id])
      }
    })
    server.get(`${apiPrefix}/admin/forms`, (schema, request) => {
      const forms = schema.db.hysforms

      const result = {
        hysforms: forms
      }
      return new Response(200, {}, result)
    })

    server.get(`${apiPrefix}/admin/forms/:hysform_id`, (schema, request) => {
      const hysform_id = parseInt(request.params.hysform_id)
      const form = schema.db.hysforms.find(hysform_id)

      let type_name = "Recipient Profile"

      if (form.type === "donor") {
        type_name = "Donor Profile"
      }

      const result = {
        hysform: form,
        notify: null,
        type_name: type_name,
        lists: {
            "": "None"
        },
        mailchimp_list_name: false,
        emailsettings: []
      }
      return new Response(200, {}, result)
    })

    server.post(`${apiPrefix}/admin/forms`, (schema, {requestBody}) => {
      const body = JSON.parse(requestBody)

      const hysform = {
        id: Math.floor(Math.random() * 10000) + 1,
        client_id: parseInt(body.client_id),
        default_emailset_id: 0,
        type: body.type,
        name: body.name,
        prefix: "",
        counter: 0,
        box_folder_id: "",
        can_donor_modify_amount: "",
        mailchimp_list_id: "",
        forgive_missed_payments: 0,
        notify: null,
        created_at: new Date().toISOString()
      }

      schema.db.hysforms.insert(hysform)

      return new Response(200, {}, true)
    })

    server.put(`${apiPrefix}/admin/forms/:hysform_id`, (schema, request) => {
      const body = JSON.parse(request.requestBody)
      const id = parseInt(request.params.hysform_id)
      schema.db.hysforms.update({id}, body)
      return new Response(200, {}, true)
    })

    server.delete(`${apiPrefix}/admin/forms/:hysform_id`, (schema, request) => {
      const hysform_id = parseInt(request.params.hysform_id)
      schema.db.hysforms.remove({id: hysform_id})
      return new Response(200, {}, true)
    })

    server.get(`${apiPrefix}/admin/program/:program_id/statistics`, (schema, request) => {
      const { program_id } = request.params;
      return new Response(200, {}, schema.db.programs_statistics[0][program_id]);
    })

    server.get(`${apiPrefix}/admin/donor-activities/:donor_id`, (schema, request) => {
      const donor_id = parseInt(request.params.donor_id)
      return new Response(200, {}, donor_activities[donor_id])
    })
  
    server.get(`${apiPrefix}/file/:type/:id/upload`, (schema, request) => {
      const donor_id = parseInt(request.params.id)
      const type = request.params.type

      if (type === "donor") {
        return new Response(200, {}, uploads[donor_id])
      }
    })

    server.get(`${apiPrefix}/admin/auto_emails`, (schema, request) => {
      const emailsets = schema.db.emailsets
      const result = {
        emailsets: emailsets,
        template_errors: schema.db.programOptionsData[0]['auto_emails']['template_errors'],
        t_array: schema.db.programOptionsData[0]['auto_emails']['t_array']
      }
      return new Response(200, {}, result)
    })

    server.delete(`${apiPrefix}/admin/auto_emails/emailset/:emailset_id`, (schema, request) => {
      const emailset_id = parseInt(request.params.emailset_id)
      schema.db.emailsets.remove({id: emailset_id})
      return new Response(200, {}, true)
    })

    server.get(`${apiPrefix}/admin/entities/:program_id`, (schema, request) => {
      const { program_id } = request.params;
      const query = request.queryParams.query?.toLowerCase();

      const result = schema.db.program_entities.find(program_id)

      if (query) {
        
        return true
      }
      return new Response(200, {}, result);
    })
  
    server.get(`${apiPrefix}/admin/entities/:program_id/headers`, (schema, request) => {
      const { program_id } = request.params;
      return new Response(200, {}, schema.db.programs_headers[0][program_id]);
    })
  
    server.get(`${apiPrefix}/admin/fields/options/:program_id/:type`, (schema, request) => {
      const { program_id } = request.params;
      return new Response(200, {}, schema.db.programs_fields[0][program_id]);
    })

    server.get(`${apiPrefix}/admin/designation`, (schema, request) => {
      const result = {
        hysforms: {
          "639": "Sponsor Profiles",
          "3030": "new test form",
          "3040": "Kupenda Donors"
        },
        emailsets: schema.db.emailsets
      }
      return new Response(200, {}, result)
    })

    server.get(`${apiPrefix}/admin/designations`, (schema, request) => {
      const result = {
        designations: schema.db.designations
      }
      return new Response(200, {}, result)
    })

    server.post(`${apiPrefix}/admin/designation`, (schema, {requestBody}) => {
      const body = JSON.parse(requestBody)
      const designation = {
        "id": Math.floor(Math.random() * 10000) + 1,
        "client_id": parseInt(body.client_id),
        "emailset_id": body.emailset_id,
        "hysforms": body.hysform,
        "code": body.code,
        "name": body.name,
        "info": "",
        "donation_amounts": "",
      }

      schema.db.designations.insert(designation)

      return new Response(200, {}, true)
    })

    server.get(`${apiPrefix}/admin/designation/:designation_id`, (schema, request) => {
      const designation_id = parseInt(request.params.designation_id)
      const designation = schema.db.designations.find(designation_id)
      const result = {
        designation: designation,
        emailsets: schema.db.emailsets,
        hysforms: {
          "639": "Sponsor Profiles",
          "3030": "new test form",
          "3040": "Kupenda Donors"
        },
        used_form: designation.hysforms
      }
      return new Response(200, {}, result)
    })
  
    server.put(`${apiPrefix}/admin/designation/:designation_id`, (schema, request) => {
      const body = JSON.parse(request.requestBody)
      const id = parseInt(request.params.designation_id)
      schema.db.designations.update({id}, body)
      return new Response(200, {}, true)
    })

    server.delete(`${apiPrefix}/admin/designation/:designation_id`, (schema, request) => {
      const designation_id = parseInt(request.params.designation_id)
      schema.db.designations.remove({id: designation_id})
      return new Response(200, {}, true)
    })

    server.get(`${apiPrefix}/admin/multi_program_select`, (schema, request) => {
      return new Response(200, {}, schema.db.programOptionsData[0]['url_generator'])
    })

    server.get(`${apiPrefix}/admin/settings`, (schema, request) => {
      const settings = schema.db.settings
      const result = {
        settings: settings
      }
      return new Response(200, {}, result)
    })

    server.get(`${apiPrefix}/admin/settings/:settings_id`, (schema, request) => {
      const settings_id = parseInt(request.params.settings_id)
      return new Response(200, {}, schema.db.settings[settings_id])
    })

    server.delete(`${apiPrefix}/admin/settings/:settings_id`, (schema, request) => {
      const settings_id = parseInt(request.params.settings_id)
      schema.db.settings.remove({id: settings_id})
      return new Response(200, {}, true)
    })

    server.delete(`${apiPrefix}/admin/program/:program_id`, (schema, request) => {
      const program_id = parseInt(request.params.program_id)
      schema.db.programs.remove({id: program_id})
      return new Response(200, {}, true)
    })

    server.post(`${apiPrefix}/admin/program`, (schema, {requestBody}) => {
      const body = JSON.parse(requestBody)

      const program = {
        id: Math.floor(Math.random() * 10000) + 1,
        client_id: parseInt(body.client_id),
        hysform_id: null,
        donor_hysform_id: null,
        setting_id: null,
        emailset_id: null,
        entity_submit: null,
        donor_submit: null,
        link_id: null,
        name: body.name,
        prefix: body.prefix,
        counter: null,
        lft: 2,
        rgt: 3,
        tree: parseInt(body.client_id),
        box_folder_id: null,
        children: []
      }

      schema.db.programs.insert(program)

      return new Response(200, {}, true)
    })

    server.post(`${apiPrefix}/admin/program/sub_program`, (schema, {requestBody}) => {
      const body = JSON.parse(requestBody)

      const program = {
        id: Math.floor(Math.random() * 10000) + 1,
        client_id: parseInt(body.client_id),
        hysform_id: null,
        donor_hysform_id: null,
        setting_id: null,
        emailset_id: null,
        entity_submit: null,
        donor_submit: null,
        link_id: body.link_id,
        name: body.name,
        prefix: null,
        counter: null,
        lft: 2,
        rgt: 3,
        tree: parseInt(body.client_id),
        box_folder_id: null,
        children: []
      }

      schema.db.programs.insert(program)

      return new Response(200, {}, true)
    })

    server.get(`${apiPrefix}/admin/forms/:hysform_id/manage`, (schema, request) => {
      const id = parseInt(request.params.hysform_id)
      const fields = schema.db.fields.where({hysform_id: id})
      const hysform = schema.db.hysforms.find(id)

      let type_name = "Recipient Profile"

      if (hysform.type === "donor") {
        type_name = "Donor Profile"
      }
      
      const result = {
        fields: fields,
        hysform: hysform,
        type_name: type_name,
        gateway: "stripe",
        mailchimp_list_name: false
      }

      return new Response(200, {}, result)
    })

    server.delete(`${apiPrefix}/admin/form/field`, (schema, {requestBody}) => {
      const body = JSON.parse(requestBody)
      const id = body.id
      schema.db.fields.remove(id)

      return new Response(200, {}, true)
    })

    server.get(`${apiPrefix}/admin/form/field/:hysform_id/:type`, (schema, request) => {
      const hysform_id = request.params.hysform_id
      const type = request.params.type

      const hysform = schema.db.hysforms.find(hysform_id)

      let type_name = "Recipient Profile"

      if (type === "donor") {
        type_name = "Donor Profile"
      }

      const result = {
        hysform: hysform,
        type: type,
        type_name: type_name,
        field_type: {
          "hysText": "Text",
          "hysTextarea": "Textarea",
          "hysStatic": "Static Text",
          "hysAge": "Age",
          "hysDate": "Date",
          "hysLink": "Link",
          "hysSelect": "Select List",
          "hysCheckbox": "Checkbox",
          "hysTable": "Table",
          "hysCustomid": "Auto increment ID"
        },
        gateway: "stripe",
      }
      return new Response(200, {}, result)
    })

    // 3521
    // Sustainable Living Expo

    server.get(`${apiPrefix}/admin/program/entities/:id`, (schema, request) => {
      const { id } = request.params;
      return new Response(200, {}, schema.db.programs_entities.find(id));
    })

    server.patch(`${apiPrefix}/admin/program/entity`, (schema, {requestBody}) => {
      const body = JSON.parse(requestBody)
      const id = body.id
      const entity = schema.db.programs_entities.find(id)
      schema.db.programs_entities.update(id, entity)
      return new Response(200, {}, true)
    })

    // server.put(`${apiPrefix}/admin/form/field`, (schema, request) => {
    //   return new Response(200, {}, programsData['admin/form/field'])
    // })
  
    // server.post(`${apiPrefix}/csv_process/{program_id}`, (schema, request) => {
    //   // TODO: Handle dynamic parameters from request.params
    //   return new Response(200, {}, programsData['csv_process/*'])
    // })
  
    // server.post(`${apiPrefix}/csv_process/{program_id}/relationships`, (schema, request) => {
    //   // TODO: Handle dynamic parameters from request.params
    //   return new Response(200, {}, programsData['csv_process/*/relationships'])
    // })
  
    // server.post(`${apiPrefix}/csv_process/{program_id}/payments`, (schema, request) => {
    //   // TODO: Handle dynamic parameters from request.params
    //   return new Response(200, {}, programsData['csv_process/*/payments'])
    // })
  
    // server.get(`${apiPrefix}/admin/message/send/{data.entity_id}/{data.donor_id}/{data.from_title}`, (schema, request) => {
    //   // TODO: Handle dynamic parameters from request.params
    //   return new Response(200, {}, programsData['admin/message/send/*/*/*'])
    // })
  
    // server.put(`${apiPrefix}/file/{data.id}`, (schema, request) => {
    //   // TODO: Handle dynamic parameters from request.params
    //   return new Response(200, {}, programsData['file/*'])
    // })
  
    // server.post(`${apiPrefix}/admin/recordUpload`, (schema, request) => {
    //   // TODO: Handle dynamic parameters from request.params
    //   return new Response(200, {}, programsData['admin/recordUpload'])
    // })
  
    // server.get(`${apiPrefix}/picture/{data.id}/profile`, (schema, request) => {
    //   // TODO: Handle dynamic parameters from request.params
    //   return new Response(200, {}, programsData['picture/*/profile'])
    // })
  
    // server.delete(`${apiPrefix}/file/{data.id}`, (schema, request) => {
    //   // TODO: Handle dynamic parameters from request.params
    //   return new Response(200, {}, programsData['file/*'])
    // })
  
    // server.get(`${apiPrefix}/admin/campaign/settings/{data.settings_id}`, (schema, request) => {
    //   // TODO: Handle dynamic parameters from request.params
    //   return new Response(200, {}, programsData['admin/campaign/settings/*'])
    // })
  
    // server.post(`${apiPrefix}/admin/settings`, (schema, request) => {
    //   // TODO: Handle dynamic parameters from request.params
    //   return new Response(200, {}, programsData['admin/settings'])
    // })
  
    // server.put(`${apiPrefix}/admin/settings/{data.id}`, (schema, request) => {
    //   // TODO: Handle dynamic parameters from request.params
    //   return new Response(200, {}, programsData['admin/settings/*'])
    // })
  
    // server.post(`${apiPrefix}/admin/entity/{data.program_id}`, (schema, request) => {
    //   // TODO: Handle dynamic parameters from request.params
    //   return new Response(200, {}, programsData['admin/entity/*'])
    // })
  
    // server.post(`${apiPrefix}/admin/auto_emails/emailset`, (schema, request) => {
    //   // TODO: Handle dynamic parameters from request.params
    //   return new Response(200, {}, programsData['admin/auto_emails/emailset'])
    // })
  
    // server.get(`${apiPrefix}/admin/auto_emails/template/{data.emailset_id}/{data.trigger}`, (schema, request) => {
    //   // TODO: Handle dynamic parameters from request.params
    //   return new Response(200, {}, programsData['admin/auto_emails/template/*/*'])
    // })
  
    // server.put(`${apiPrefix}/admin/auto_emails/template`, (schema, request) => {
    //   // TODO: Handle dynamic parameters from request.params
    //   return new Response(200, {}, programsData['admin/auto_emails/template'])
    // })
  
    // server.get(`${apiPrefix}/admin/entity/{data.program_id}`, (schema, request) => {
    //   // TODO: Handle dynamic parameters from request.params
    //   return new Response(200, {}, programsData['admin/entity/*'])
    // })
  
    // server.post(`${apiPrefix}/admin/fields/options/{params.program_id}`, (schema, request) => {
    //   // TODO: Handle dynamic parameters from request.params
    //   return new Response(200, {}, programsData['admin/fields/options/*'])
    // })
  
    // server.delete(`${apiPrefix}/admin/view/{data.report_id}/{data.program_id}`, (schema, request) => {
    //   // TODO: Handle dynamic parameters from request.params
    //   return new Response(200, {}, programsData['admin/view/*/*'])
    // })
  
    // server.delete(`${apiPrefix}/admin/entities/{data.program_id}`, (schema, request) => {
    //   // TODO: Handle dynamic parameters from request.params
    //   return new Response(200, {}, programsData['admin/entities/*'])
    // })
  
    // server.post(`${apiPrefix}/admin/multi_program_select`, (schema, request) => {
    //   // TODO: Handle dynamic parameters from request.params
    //   return new Response(200, {}, programsData['admin/multi_program_select'])
    // })
  
    // server.patch(`${apiPrefix}/admin/program/entities`, (schema, request) => {
    //   // TODO: Handle dynamic parameters from request.params
    //   return new Response(200, {}, programsData['admin/program/entities'])
    // })
  
    // server.post(`${apiPrefix}/admin/message/send/{data.entity_id}/{data.from_title}`, (schema, request) => {
    //   // TODO: Handle dynamic parameters from request.params
    //   return new Response(200, {}, programsData['admin/message/send/*/*'])
    // })
  
    // server.post(`${apiPrefix}/admin/message/approve/draft/{data.email_id}`, (schema, request) => {
    //   // TODO: Handle dynamic parameters from request.params
    //   return new Response(200, {}, programsData['admin/message/approve/draft/*'])
    // })
  
    // server.post(`${apiPrefix}/admin/message/drafts/approve`, (schema, request) => {
    //   // TODO: Handle dynamic parameters from request.params
    //   return new Response(200, {}, programsData['admin/message/drafts/approve'])
    // })
  
    // server.delete(`${apiPrefix}/admin/messages/drafts/delete`, (schema, request) => {
    //   // TODO: Handle dynamic parameters from request.params
    //   return new Response(200, {}, programsData['admin/messages/drafts/delete'])
    // })
  
    // server.put(`${apiPrefix}/admin/message/draft/response/{data.email_id}/approve`, (schema, request) => {
    //   // TODO: Handle dynamic parameters from request.params
    //   return new Response(200, {}, programsData['admin/message/draft/response/*/approve'])
    // })
  
    // server.post(`${apiPrefix}/admin/message/draft/{data.entity_id}/{data.from_title}`, (schema, request) => {
    //   // TODO: Handle dynamic parameters from request.params
    //   return new Response(200, {}, programsData['admin/message/draft/*/*'])
    // })
  
    // server.patch(`${apiPrefix}/admin/message/draft/{data.email_id}/edit`, (schema, request) => {
    //   // TODO: Handle dynamic parameters from request.params
    //   return new Response(200, {}, programsData['admin/message/draft/*/edit'])
    // })
  
    // server.post(`${apiPrefix}/admin/notes/{data.type}/{data.program_id}`, (schema, request) => {
    //   // TODO: Handle dynamic parameters from request.params
    //   return new Response(200, {}, programsData['admin/notes/*/*'])
    // })
  
    // server.get(`${apiPrefix}/csv_import/{program_id}`, (schema, request) => {
    //   // TODO: Handle dynamic parameters from request.params
    //   return new Response(200, {}, programsData['csv_import/*'])
    // })
  
    // server.post(`${apiPrefix}/csv_import/{program_id}`, (schema, request) => {
    //   // TODO: Handle dynamic parameters from request.params
    //   return new Response(200, {}, programsData['csv_import/*'])
    // })
  
    // server.post(`${apiPrefix}/admin/move/{data.entity_id}`, (schema, request) => {
    //   // TODO: Handle dynamic parameters from request.params
    //   return new Response(200, {}, programsData['admin/move/*'])
    // })
  
    // server.put(`${apiPrefix}/admin/entities/move`, (schema, request) => {
    //   // TODO: Handle dynamic parameters from request.params
    //   return new Response(200, {}, programsData['admin/entities/move'])
    // })
  
    // server.get(`${apiPrefix}/admin/move/{data.entity_id}/compatible_programs`, (schema, request) => {
    //   // TODO: Handle dynamic parameters from request.params
    //   return new Response(200, {}, programsData['admin/move/*/compatible_programs'])
    // })
  
    // server.delete(`${apiPrefix}/admin/entity/{data.entity_id}/delete`, (schema, request) => {
    //   // TODO: Handle dynamic parameters from request.params
    //   return new Response(200, {}, programsData['admin/entity/*/delete'])
    // })
  
    // server.get(`${apiPrefix}/admin/entity/{data.entity_id}/activate`, (schema, request) => {
    //   // TODO: Handle dynamic parameters from request.params
    //   return new Response(200, {}, programsData['admin/entity/*/activate'])
    // })
  
    // server.post(`${apiPrefix}/admin/entities/{data.program_id}/activate`, (schema, request) => {
    //   // TODO: Handle dynamic parameters from request.params
    //   return new Response(200, {}, programsData['admin/entities/*/activate'])
    // })
  
    // server.delete(`${apiPrefix}/admin/entity/{data.entity_id}`, (schema, request) => {
    //   // TODO: Handle dynamic parameters from request.params
    //   return new Response(200, {}, programsData['admin/entity/*'])
    // })
  
    // server.delete(`${apiPrefix}/admin/entities/{data.program_id}/delete`, (schema, request) => {
    //   // TODO: Handle dynamic parameters from request.params
    //   return new Response(200, {}, programsData['admin/entities/*/delete'])
    // })
  
    // server.post(`${apiPrefix}/admin/notes`, (schema, request) => {
    //   // TODO: Handle dynamic parameters from request.params
    //   return new Response(200, {}, programsData['admin/notes'])
    // })
  
    // server.get(`${apiPrefix}/admin/notes/{data.note_id}/{data.program_id}`, (schema, request) => {
    //   // TODO: Handle dynamic parameters from request.params
    //   return new Response(200, {}, programsData['admin/notes/*/*'])
    // })
  
    // server.put(`${apiPrefix}/admin/notes/{data.note_id}/{data.program_id}`, (schema, request) => {
    //   // TODO: Handle dynamic parameters from request.params
    //   return new Response(200, {}, programsData['admin/notes/*/*'])
    // })
  
    // server.post(`${apiPrefix}/admin/select_saved_report`, (schema, request) => {
    //   // TODO: Handle dynamic parameters from request.params
    //   return new Response(200, {}, programsData['admin/select_saved_report'])
    // })
  
    // server.get(`${apiPrefix}/program/{data.id}/settings`, (schema, request) => {
    //   // TODO: Handle dynamic parameters from request.params
    //   return new Response(200, {}, programsData['program/*/settings'])
    // })
  
    // server.put(`${apiPrefix}/program/{data.program_id}`, (schema, request) => {
    //   // TODO: Handle dynamic parameters from request.params
    //   return new Response(200, {}, programsData['program/*'])
    // })
  
    // server.post(`${apiPrefix}/admin/program/settings`, (schema, request) => {
    //   // TODO: Handle dynamic parameters from request.params
    //   return new Response(200, {}, programsData['admin/program/settings'])
    // })
  
    // server.post(`${apiPrefix}/admin/program/form/sponsorship`, (schema, request) => {
    //   // TODO: Handle dynamic parameters from request.params
    //   return new Response(200, {}, programsData['admin/program/form/sponsorship'])
    // })
  
    // server.post(`${apiPrefix}/admin/program/form/donor`, (schema, request) => {
    //   // TODO: Handle dynamic parameters from request.params
    //   return new Response(200, {}, programsData['admin/program/form/donor'])
    // })
  
    // server.post(`${apiPrefix}/admin/program/emailset`, (schema, request) => {
    //   // TODO: Handle dynamic parameters from request.params
    //   return new Response(200, {}, programsData['admin/program/emailset'])
    // })
  
    // server.get(`${apiPrefix}/admin/form/fields/order/{params.type}`, (schema, request) => {
    //   // TODO: Handle dynamic parameters from request.params
    //   return new Response(200, {}, programsData['admin/form/fields/order/*'])
    // })
  
    // server.post(`${apiPrefix}/admin/form/bulk_edit`, (schema, request) => {
    //   // TODO: Handle dynamic parameters from request.params
    //   return new Response(200, {}, programsData['admin/form/bulk_edit'])
    // })
  
    // server.post(`${apiPrefix}/admin/form/field/{data[0].hysform_id}/{data[0].type}`, (schema, request) => {
    //   // TODO: Handle dynamic parameters from request.params
    //   return new Response(200, {}, programsData['admin/form/field/*/*'])
    // })
  
    // server.post(`${apiPrefix}/admin/program/form`, (schema, request) => {
    //   // TODO: Handle dynamic parameters from request.params
    //   return new Response(200, {}, programsData['admin/program/form'])
    // })
  
    // server.get(`${apiPrefix}/admin/programs/view/{data.report_id}/{data.program_id}`, (schema, request) => {
    //   // TODO: Handle dynamic parameters from request.params
    //   return new Response(200, {}, programsData['admin/programs/view/*/*'])
    // })
  
    // server.get(`${apiPrefix}/picture/{data.id}/rotate`, (schema, request) => {
    //   // TODO: Handle dynamic parameters from request.params
    //   return new Response(200, {}, programsData['picture/*/rotate'])
    // })
  
    // server.get(`${apiPrefix}/admin/auto_emails/send/{data.program_id}/{data.trigger}/{data.to}/{data.donor_id}/{data.entity_id}`, (schema, request) => {
    //   // TODO: Handle dynamic parameters from request.params
    //   return new Response(200, {}, programsData['admin/auto_emails/send/*/*/*/*/*'])
    // })
  
    // server.get(`${apiPrefix}/frontend/entities/random/{data.client_id}/{data.program_id}`, (schema, request) => {
    //   // TODO: Handle dynamic parameters from request.params
    //   return new Response(200, {}, programsData['frontend/entities/random/*/*'])
    // })
  
    // server.get(`${apiPrefix}/admin/add_settings`, (schema, request) => {
    //   // TODO: Handle dynamic parameters from request.params
    //   return new Response(200, {}, programsData['admin/add_settings'])
    // })
  
    // server.get(`${apiPrefix}/program/{data}`, (schema, request) => {
    //   // TODO: Handle dynamic parameters from request.params
    //   return new Response(200, {}, programsData['program/*'])
    // })
  
    // server.get(`${apiPrefix}/program/{data}/campaign`, (schema, request) => {
    //   // TODO: Handle dynamic parameters from request.params
    //   return new Response(200, {}, programsData['program/*/campaign'])
    // })
  
    // server.get(`${apiPrefix}/admin/list_archived_forms/{data.type}/{data.Id}/{data.hysformId}`, (schema, request) => {
    //   // TODO: Handle dynamic parameters from request.params
    //   return new Response(200, {}, programsData['admin/list_archived_forms/*/*/*'])
    // })
  
    // server.get(`${apiPrefix}/admin/submit_form/{data.type}/{data.id}/{data.program_id}/{data.form_id}`, (schema, request) => {
    //   // TODO: Handle dynamic parameters from request.params
    //   return new Response(200, {}, programsData['admin/submit_form/*/*/*/*'])
    // })
  
    // server.post(`${apiPrefix}/admin/submit_form/{data.type}/{data.id}/{data.program_id}/{data.form_id}`, (schema, request) => {
    //   // TODO: Handle dynamic parameters from request.params
    //   return new Response(200, {}, programsData['admin/submit_form/*/*/*/*'])
    // })
  
    // server.get(`${apiPrefix}/admin/view_archived_form/{data}`, (schema, request) => {
    //   // TODO: Handle dynamic parameters from request.params
    //   return new Response(200, {}, programsData['admin/view_archived_form/*'])
    // })
  
    // server.get(`${apiPrefix}/admin/edit_archived_form/{data}`, (schema, request) => {
    //   // TODO: Handle dynamic parameters from request.params
    //   return new Response(200, {}, programsData['admin/edit_archived_form/*'])
    // })
  
    // server.post(`${apiPrefix}/admin/edit_archived_form/{data.id}`, (schema, request) => {
    //   // TODO: Handle dynamic parameters from request.params
    //   return new Response(200, {}, programsData['admin/edit_archived_form/*'])
    // })
  
    // server.get(`${apiPrefix}/admin/remove_submit_form/{type}/{program_id}/{data.id}`, (schema, request) => {
    //   // TODO: Handle dynamic parameters from request.params
    //   return new Response(200, {}, programsData['admin/remove_submit_form/*/*/*'])
    // })
  }