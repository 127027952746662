import { createServer } from 'miragejs'
import appConfig from '@/configs/app.config'
import { notificationListData, searchQueryPoolData } from './data/commonData'
import {
    projectList,
    scrumboardData,
    issueData,
    projectDashboardData,
} from './data/projectData'
import { usersData, userDetailData } from './data/usersData'
import { eventsData, mailData, crmDashboardData, admins, emails, newsLetterData, newsLetterDetail  } from './data/crmData'
import {
    productsData,
    ordersData,
    orderDetailsData,
    salesDashboardData,
} from './data/salesData'
import {
    portfolioData,
    walletsData,
    marketData,
    transactionHistoryData,
    cryptoDashboardData,
} from './data/cryptoData'
import {
    settingData,
    settingIntergrationData,
    settingBillingData,
    invoiceData,
    logData,
    accountFormData,
    account,
    template,
    payments,
    creditCard,
    intergrations,
    integrationsData
} from './data/accountData'
import {
    helpCenterCategoriesData,
    helpCenterArticleListData,
} from './data/knowledgeBaseData'
import { signInUserData } from './data/authData'
import { adminData, activities, groups, administrators, groupdata, createGroup } from './data/adminData'
import { progressReportsData } from './data/archivedreportsData'
import { programsData, programOptionsData, hysforms, designations, emailsets, settings, sponsorships_programs, programs_headers, programs_statistics, program_entities, programs_entities, programs_fields, programs, archived, fields } from './data/programsData'
import { 
    forms,
    all_donors,
    donors,
    headers,
    statistics,
    available,
    archived_donors,
    files,
    profiles,
    donor_donations,
    donor_sponsorships,
    uploads,
    creditCards,
    donor_activities,
    add_donor,
    add_sponsorship,
    add_payment
} from './data/donorData'
import { sponsorships_fields, sponsorships_headers, sponsorships_statistics, sponsorships } from './data/sponsorshipData'
import { donations, donationsData } from './data/donationData'

import {
    commonFakeApi,
    projectFakeApi,
    crmFakeApi,
    salesFakeApi,
    accountFakeApi,
    cryptoFakeApi,
    authFakeApi,
    knowledgeBaseFakeApi,
    donationFakeApi,
    programsFakeApi,
    donorFakeApi,
    sponsorshipFakeApi,
    adminFakeApi,
    archivedreportsData
} from './fakeApi'

const { apiPrefix } = appConfig

export function mockServer({ environment = 'demo' }) {
    return createServer({
        environment,
        seeds(server) {
            server.db.loadData({
                notificationListData,
                searchQueryPoolData,
                projectList,
                scrumboardData,
                issueData,
                usersData,
                userDetailData,
                eventsData,
                mailData,
                productsData,
                ordersData,
                orderDetailsData,
                settingData,
                settingIntergrationData,
                settingBillingData,
                invoiceData,
                logData,
                accountFormData,
                portfolioData,
                walletsData,
                marketData,
                transactionHistoryData,
                helpCenterCategoriesData,
                helpCenterArticleListData,
                signInUserData,
                salesDashboardData,
                crmDashboardData,
                projectDashboardData,
                cryptoDashboardData,
                activities,
                progressReportsData,
                programsData,
                newsLetterData,
                newsLetterDetail,
                groups,
                administrators,
                groupdata,
                createGroup,
                integrationsData,
                programOptionsData,
                hysforms,
                emailsets,
                designations,
                settings,
                account,
                template,
                forms,
                all_donors,
                donors,
                headers,
                statistics,
                available,
                sponsorships_fields,
                sponsorships_programs,
                sponsorships_headers,
                sponsorships_statistics,
                sponsorships,
                donationsData,
                admins,
                emails,
                adminData,
                donations,
                payments,
                creditCard,
                intergrations,
                archived_donors,
                files,
                profiles,
                donor_donations,
                donor_sponsorships,
                uploads,
                creditCards,
                donor_activities,
                add_donor,
                add_sponsorship,
                add_payment,
                programs_headers,
                programs_statistics,
                program_entities,
                programs_entities,
                programs_fields,
                programs,
                archived,
                fields
            })
        },
        routes() {
            this.urlPrefix = ''
            this.namespace = ''
            this.passthrough((request) => {
                const isExternal = request.url.startsWith('http')
                const isResource = request.url.startsWith('data:text')
                return isExternal || isResource
            })
            this.passthrough()

            commonFakeApi(this, apiPrefix)
            projectFakeApi(this, apiPrefix)
            crmFakeApi(this, apiPrefix)
            salesFakeApi(this, apiPrefix)
            accountFakeApi(this, apiPrefix)
            authFakeApi(this, apiPrefix)
            cryptoFakeApi(this, apiPrefix)
            knowledgeBaseFakeApi(this, apiPrefix)
            programsFakeApi(this, apiPrefix)
            donorFakeApi(this, apiPrefix)
            sponsorshipFakeApi(this, apiPrefix)
            adminFakeApi(this, apiPrefix)
            donationFakeApi(this, apiPrefix)
            archivedreportsData(this, apiPrefix)
        },
    })
}
