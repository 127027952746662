import { Server, Response } from 'miragejs'
import { activityLogData } from '../data/adminData'
import { donations, donationsData } from '../data/donationData'
import { donor_donations } from '../data/donorData'

export default function donationFakeApi(server: Server, apiPrefix: string) {
  server.get(`${apiPrefix}/admin/donations/options`, (schema, request) => {
    // TODO: Handle dynamic parameters from request.params
    return new Response(200, {}, donationData['admin/donations/options'])
  })

  server.get(`${apiPrefix}/admin/form/{data.Donor_Group}/fields`, (schema, request) => {
    // TODO: Handle dynamic parameters from request.params
    return new Response(200, {}, donationData['admin/form/*/fields'])
  })

  server.get(`${apiPrefix}/admin/donations/:donation_id`, (schema, request) => {
    return new Response(200, {}, donationsData)
  })

  server.post(`${apiPrefix}/admin/donations`, (schema, request) => {
    // TODO: Handle dynamic parameters from request.params
    return new Response(200, {}, donationData['admin/donations'])
  })

  server.get(`${apiPrefix}/admin/donors/donations`, (schema, request) => {
    return new Response(200, {}, donations)
  })

  server.get(`${apiPrefix}/admin/donors/:donor_id/donations`, (schema, request) => {
    const donor_id = parseInt(request.params.donor_id)
    return new Response(200, {}, donor_donations[donor_id])
  })

  server.post(`${apiPrefix}/admin/donation`, (schema, request) => {
    // TODO: Handle dynamic parameters from request.params
    return new Response(200, {}, donationData['admin/donation'])
  })

  server.get(`${apiPrefix}/admin/donation/{data.donation_id}`, (schema, request) => {
    // TODO: Handle dynamic parameters from request.params
    return new Response(200, {}, donationData['admin/donation/*'])
  })

  server.put(`${apiPrefix}/admin/donation/{data.donation_id}`, (schema, request) => {
    // TODO: Handle dynamic parameters from request.params
    return new Response(200, {}, donationData['admin/donation/*'])
  })

  server.delete(`${apiPrefix}/admin/donation/{data.donation_id}`, (schema, request) => {
    // TODO: Handle dynamic parameters from request.params
    return new Response(200, {}, donationData['admin/donation/*'])
  })

  server.get(`${apiPrefix}/admin/export_donations`, (schema, request) => {
    // TODO: Handle dynamic parameters from request.params
    return new Response(200, {}, donationData['admin/export_donations'])
  })
}