import ApiService from "./ApiService";

export async function apiGetDonorLogin(params) {
  return ApiService.fetchData({
    url: `/frontend/login/${params.client_id}/${params.program_id}`,
    method: "get",
  });
}

export async function apiPostDonorLogin(params, data) {
  return ApiService.fetchData({
    url: `/frontend/login/${params.client_id}/${params.program_id}/${params.sessionid}`,
    method: "post",
    data,
  });
}

export async function apiPostDonorForgotUsername(params, data) {
  return ApiService.fetchData({
    url: `/frontend/forgot_username/${params.client_id}/${params.program_id}/${params.session_id}`,
    method: "post",
    data,
  });
}

export async function apiPostDonorForgotPassword(params, data) {
  return ApiService.fetchData({
    url: `/frontend/reset_password/${params.client_id}/${params.program_id}/${params.session_id}`,
    method: "post",
    data,
  });
}

export async function apiPostDonorUploadFile(data) {
  return ApiService.fetchData({
    url: `/frontend/donor/recordUpload/${data.client_id}/${data.session_id}`,
    method: "post",
    data,
  });
}

export async function apiGetDisplayTitlesAndFiles(params) {
  return ApiService.fetchData({
    url: `/frontend/entities/all/${params.client_id}/${params.program_id}/${params.session_id}`,
    method: "get",
    params,
  });
}
export async function apiGetDisplayDataNoSession(params) {
  return ApiService.fetchData({
    url: `/frontend/entities/all/${params.client_id}/${params.program_id}`,
    method: "get",
    params,
  });
}

export async function apiGetDisplayTitlesAndFilesPagination(params) {
  return ApiService.fetchData({
    url: `/frontend/entities/pagination/${params.client_id}/${params.program_id}/${params.session_id}`,
    method: "get",
    params
  });
}

export async function apiGetDisplayTitlesAndFilesPaginationNoSession(params) {
  return ApiService.fetchData({
    url: `/frontend/entities/pagination/${params.client_id}/${params.program_id}`,
    method: "get",
    params
  });
}

export async function apiViewEntity(params) {
  return ApiService.fetchData({
    url: `/frontend/entity/${params.client_id}/${params.program_id}/${params.entity_id}/${params.session_id}`,
    method: "get",
  });
}

export async function apiSaveEntity(params, data) {
  return ApiService.fetchData({
    url: `/frontend/entity/save/${params.client_id}/${params.program_id}/${params.entity_id}/${params.session_id}`,
    method: "post",
    data,
  });
}

export async function apiGetCheckOutData(params) {
  return ApiService.fetchData({
    url: `/frontend/order/${params.client_id}/${params.program_id}/${params.session_id}`,
    method: "get",
  });
}

export async function apiGetSponsorshipListData(params) {
  return ApiService.fetchData({
    url: `/frontend/order/sponsorships/${params.client_id}/${params.program_id}/${params.session_id}`,
    method: "get",
  });
}

export async function apiPostRemoveChild(params) {
  return ApiService.fetchData({
    url: `/frontend/checkout/entity/${params.client_id}/${params.program_id}/${params.data.entity_id}/${params.session_id}`,
    method: "delete",
  });
}

export async function apiPostAddDesignation(params, data) {
  return ApiService.fetchData({
    url: `/frontend/checkout/designation/${params.client_id}/${params.program_id}/${params.data.data.currency_symbol}/${params.session_id}`,
    method: "post",
    data,
  });
}

export async function apipostCheckoutLogin(params, data) {
  return ApiService.fetchData({
    url: `/frontend/checkout/login/${params.client_id}/${params.program_id}/${params.session_id}`,
    method: "post",
    data,
  });
}

export async function apipostCheckoutSignup(params, data) {
  return ApiService.fetchData({
    url: `/frontend/checkout/signup/${params.client_id}/${params.program_id}/${params.session_id}`,
    method: "post",
    data,
  });
}

export async function apipostCreatePaymentPlan(params, data){
  return ApiService.fetchData({
    url: `/frontend/flutterwave_payment_plan/${params.client_id}/${params.program_id}/${params.session_id}`,
    method: "post",
    data,
  });
}

export async function apiPostFlutterwaveVerifyReference(data){
  return ApiService.fetchData({
    url: `/flutterwave/verify/${data}`,
    method: "post",
    data,
  });
}

export async function apipostCheckoutCampaign(params, data) {
  return ApiService.fetchData({
    url: `/frontend/checkout/campaign/${params.client_id}/${params.program_id}`,
    method: "post",
    data,
  });
}

export async function apigetDonorDashboard(params) {
  return ApiService.fetchData({
    url: `/frontend/donor/dashboard/${params.client_id}/${params.program_id}/${params.session_id}`,
    method: "get",
    params
  });
}

export async function apiGetEntity(params) {
  return ApiService.fetchData({
    url: `/frontend/donor/dashboard/entity/${params.client_id}/${params.program_id}/${params.entity_id}/${params.session_id}`,
    method: "get",
  });
}

export async function apigetUpdateInfo(params) {
  return ApiService.fetchData({
    url: `/frontend/donor/dashboard/details/${params.client_id}/${params.program_id}/${params.session_id}`,
    method: "get",
  });
}

export async function apipostUpdateUserInfo(params, data) {
  return ApiService.fetchData({
    url: `/frontend/donor/dashboard/details/${params.client_id}/${params.program_id}/${params.session_id}`,
    method: "put",
    data,
  });
}

export async function apigetDonorUpdateCard(params) {
  return ApiService.fetchData({
    url: `/frontend/donor/dashboard/card/${params.client_id}/${params.program_id}/${params.session_id}`,
    method: "get",
  });
}

export async function apiUploadFile(params, data) {
  return ApiService.fetchData({
    url: `/frontend/donor/dashboard/upload/${params.client_id}/${params.program_id}/${params.entity_id}/${params.session_id}`,
    method: "post",
    data,
  });
}

export async function apiGetUploads(params) {
  return ApiService.fetchData({
    url: `/frontend/donor/dashboard/files/${params.client_id}/${params.program_id}/${params.id}/${params.entity_id}/${params.session_id}`,
    method: "get",
  });
}

export async function apipostUpdateDonorCard(params, data) {
  return ApiService.fetchData({
    url: `/frontend/donor/dashboard/card/${params.client_id}/${params.program_id}/${params.session_id}`,
    method: "post",
    data,
  });
}

export async function apipostUpdatePaypal(params, data) {
  return ApiService.fetchData({
    url: `/frontend/donor/dashboard/paypal/${params.client_id}/${params.program_id}/${params.session_id}`,
    method: "post",
    data,
  });
}

export async function apipostApprovePaypal(params, data) {
  return ApiService.fetchData({
    url: `/frontend/donor/dashboard/approve_paypal/${params.client_id}/${params.program_id}/${params.session_id}`,
    method: "post",
    data,
  });
}

export async function apigetEntityMessageHistory(params) {
  return ApiService.fetchData({
    url: `/frontend/donor/dashboard/entity/messages/${params.client_id}/${params.program_id}/${params.entity_id}/${params.session_id}`,
    method: "get",
  });
}

export async function apiDeleteUploads(params) {
  return ApiService.fetchData({
    url: `/frontend/donor/dashboard/file/${params.client_id}/${params.program_id}/${params.id}/${params.entity_id}/${params.session_id}`,
    method: "delete",
  });
}

export async function apiPostReplyMail(params, data) {
  return ApiService.fetchData({
    url: `/frontend/donor/dashboard/entity/compose_message/${params.client_id}/${params.program_id}/${params.entity_id}/${params.parent_id}/${params.session_id}`,
    method: "post",
    data,
  });
}

export async function apiGetCheckOutUpdateFrequency(params) {
  return ApiService.fetchData({
    url: `/frontend/checkout/frequency/${params.client_id}/${params.program_id}/${params.entity_id}/${params.frequency}/${params.session_id}`,
    method: "put",
  });
}

export async function apiGetUploadFiles(params) {
  return ApiService.fetchData({
    url: `/frontend/donor/dashboard/uploads/${params.client_id}/${params.program_id}/${params.entity_id}/${params.session_id}`,
    method: "get",
  });
}

export async function apiGetCheckOutUpdateDesignationFrequency(params) {
  return ApiService.fetchData({
    url: `/frontend/checkout/designation/frequency/${params.client_id}/${params.program_id}/${params.designation_id}/${params.frequency}/${params.session_id}`,
    method: "put",
  });
}

export async function apiPostRemoveDesignation(params) {
  return ApiService.fetchData({
    url: `/frontend/checkout/designation/${params.client_id}/${params.program_id}/${params.entity_id}/${params.currency_symbol}/${params.session_id}`,
    method: "delete",
  });
}

export async function apiGetSignUpFields(params) {
  return ApiService.fetchData({
    url: `/frontend/signup_donor/${params.client_id}/${params.program_id}`,
    method: "get",
  });
}

export async function apiDonorSignUp(params, data) {
  return ApiService.fetchData({
    url: `/frontend/signup_donor/${params.client_id}/${params.program_id}`,
    method: "post",
    data
  });
}

export async function apiDonorLogOut(params) {
  return ApiService.fetchData({
    url: `/frontend/logout/${params.client_id}/${params.program_id}/${params.session_id}`,
    method: "get",
  });
}


export async function apiGetUpdateAmount(params) {
  return ApiService.fetchData({
    url: `/frontend/checkout/amount/${params.client_id}/${params.program_id}/${params.entity_id}/${params.amount}/${params.currency_symbol}/${params.session_id}`,
    method: "put",
  });
}

export async function apiPostFlutterwaveCheckoutSignUp(data) {
  return ApiService.fetchData({
    url: "flutterwave/payment",
    method: "post",
    data,
  });
}

export async function apiPostVerifyFlutterwaveToken(params) {
  return ApiService.fetchData({
    url: `flutterwave/verify/${params.id}`,
    method: "post",
  });
}


