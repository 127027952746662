
  import { Server, Response } from 'miragejs'
  import { all_donors, available, creditCards, donor_sponsorships, profiles, add_sponsorship, add_payment } from '../data/donorData'
  
  export default function donorFakeApi(server: Server, apiPrefix: string) {
  
    server.get(`${apiPrefix}/donors/:id/forms`, (schema, request) => {
      return new Response(200, {}, schema.db.forms[0])
    })

    server.get(`${apiPrefix}/admin/statistics/donors`, (schema, request) => {
      return new Response(200, {}, schema.db.statistics[0])
    })
  
    server.get(`${apiPrefix}/admin/donors/:id/headers`, (schema, request) => {
      return new Response(200, {}, schema.db.headers[0])
    })
  
    server.get(`${apiPrefix}/admin/all_donors`, (schema, request) => {
      const query = request.queryParams.query?.toLowerCase();
      const pageIndex = parseInt(request.queryParams.pageIndex)
      const pageSize = parseInt(request.queryParams.pageSize)

      let result = schema.db.donors

      if (query) {
        const filteredData = result.filter((donor) => 
          donor.email.toLowerCase().includes(query) || 
          donor.username.toLowerCase().includes(query)
        );

        result = {
          data: filteredData,
          filter: "false",
          all: true,
          length: filteredData.length
        };

      } else {
        // Implement pagination
        const startIndex = (pageIndex - 1) * pageSize
        const endIndex = startIndex + pageSize
        const paginatedData = result.slice(startIndex, endIndex)


        result = {
          data: paginatedData,
          filter: "false",
          all: true,
          length: result.length
        };
      }
      
      return new Response(200, {}, result)
    })
  
    server.get(`${apiPrefix}/admin/donors/:hysform_id?`, (schema, request) => {
      const filter = request.queryParams.filter

      if (filter === "true") {
        return new Response(200, {}, schema.db.archived_donors[0])
      }

      const non_filtered_donors = {
        data: schema.db.donors,
        filter: false,
        all: true,
        length: schema.db.donors.length
      }

      return new Response(200, {}, non_filtered_donors)
    })
  
    server.get(`${apiPrefix}/admin/donor-credit-card/{data.donor_id}`, (schema, request) => {
      const donor_id = parseInt(request.params.donor_id)
      return new Response(200, {}, creditCards[donor_id])
    })
  
    // server.get(`${apiPrefix}/admin/donation/{data}/refund`, (schema, request) => {
    //   return new Response(200, {}, donorData['admin/donation/*/refund'])
    // })
  
    // server.get(`${apiPrefix}/donor/paypal`, (schema, request) => {
    //   return new Response(200, {}, donorData['donor/paypal'])
    // })
  
    // server.post(`${apiPrefix}/admin/donor-credit-card/:donor_id`, (schema, request) => {
    //   return new Response(200, {}, creditCards[donor_id])
    // })
  
    // server.delete(`${apiPrefix}/admin/donor-credit-card/{data.donor_id}`, (schema, request) => {
    //   return new Response(200, {}, donorData['admin/donor-credit-card/*'])
    // })
  
    // server.post(`${apiPrefix}/admin/make_default_card/{params.donor_id}`, (schema, request) => {
    //   return new Response(200, {}, donorData['admin/make_default_card/*'])
    // })
  
    // server.post(`${apiPrefix}/donor/{data.donor_id}/paypal`, (schema, request) => {
    //   return new Response(200, {}, donorData['donor/*/paypal'])
    // })
  
    // server.get(`${apiPrefix}/frontend/donor/dashboard/uploads/{params.client_id}/{params.program_id}/{params.entity_id}/{params.session_id}`, (schema, request) => {
    //   return new Response(200, {}, donorData['frontend/donor/dashboard/uploads/*/*/*/*'])
    // })
  
    server.get(`${apiPrefix}/admin/donor/:hysform_id`, (schema, request) => {
      return new Response(200, {}, schema.db.add_donor[0])
    })
  
    server.post(`${apiPrefix}/admin/donor/:hysform_id`, (schema, {requestBody}) => {
      const body = JSON.parse(requestBody)
      const new_donor = {
        id: Math.floor(Math.random() * 100000) + 1, // Generate a random ID
        "Last Login": new Date().toISOString().split('T')[0],
        "Do Not Email": "Disabled",
        "Who Added": "Self Signup",
        "Credit Card": "None",
        "To Date": "0",
        email: body.email,
        username: body.username,
        created_at: new Date().toISOString().split('T')[0],
        updated_at: new Date().toISOString().split('T')[0]
      }

      schema.db.donors.insert(new_donor)

      return new Response(200, {}, true)
    })
  
    // server.post(`${apiPrefix}/admin/donors/archive`, (schema, request) => {
    //   return new Response(200, {}, donorData['admin/donors/archive'])
    // })
  
    server.get(`${apiPrefix}/admin/donors/:id/fields/:type`, (schema, request) => {
      return new Response(200, {}, available)
    })
  
    // server.post(`${apiPrefix}/admin/donors/field_options`, (schema, request) => {
    //   return new Response(200, {}, donorData['admin/donors/field_options'])
    // })
  
    // server.delete(`${apiPrefix}/admin/donor/view/{data.report_id}/{data.hysform_id}`, (schema, request) => {
    //   return new Response(200, {}, donorData['admin/donor/view/*/*'])
    // })
  
    // server.post(`${apiPrefix}/admin/donor/view/{params.report_id}/{params.hysform_id}`, (schema, request) => {
    //   return new Response(200, {}, donorData['admin/donor/view/*/*'])
    // })
  
    server.get(`${apiPrefix}/donor/:donor_id`, (schema, request) => {
      const donor_id = parseInt(request.params.donor_id)
      return new Response(200, {}, profiles[donor_id])
    })
  
    // server.patch(`${apiPrefix}/donor/{data.donor_id}/edit`, (schema, request) => {
    //   return new Response(200, {}, donorData['donor/*/edit'])
    // })
  
    server.get(`${apiPrefix}/admin/donor/:donor_id/sponsorships`, (schema, request) => {
      const donor_id = parseInt(request.params.donor_id)
      return new Response(200, {}, donor_sponsorships[donor_id])
    })
  
    server.post(`${apiPrefix}/admin/sponsorships/donor`, (schema, request) => {
      return new Response(200, {}, add_sponsorship)
    })
  
    // server.post(`${apiPrefix}/admin/sponsorships`, (schema, request) => {
    //   return new Response(200, {}, donorData['admin/sponsorships'])
    // })
  
    // server.post(`${apiPrefix}/admin/sponsorships/{data.donor_entity_id}/archive`, (schema, request) => {
    //   return new Response(200, {}, donorData['admin/sponsorships/*/archive'])
    // })
  
    // server.post(`${apiPrefix}/admin/sponsorships/archive`, (schema, request) => {
    //   return new Response(200, {}, donorData['admin/sponsorships/archive'])
    // })
  
    // server.post(`${apiPrefix}/admin/sponsorships/{data.donor_entity_id}/restore`, (schema, request) => {
    //   return new Response(200, {}, donorData['admin/sponsorships/*/restore'])
    // })
  
    server.get(`${apiPrefix}/admin/commitment/:commitment_id/donation`, (schema, request) => {
      return new Response(200, {}, add_payment)
    })
  
    // server.post(`${apiPrefix}/admin/commitment/{data.commitment_id}/donation`, (schema, request) => {
    //   return new Response(200, {}, donorData['admin/commitment/*/donation'])
    // })
  
    // server.get(`${apiPrefix}/admin/commitments/{data.commitment_id}`, (schema, request) => {
    //   return new Response(200, {}, donorData['admin/commitments/*'])
    // })
  
    // server.get(`${apiPrefix}/admin/donor/{data.commitment_id}/email`, (schema, request) => {
    //   return new Response(200, {}, donorData['admin/donor/*/email'])
    // })
  
    // server.post(`${apiPrefix}/admin/donors/notify`, (schema, request) => {
    //   return new Response(200, {}, donorData['admin/donors/notify'])
    // })
  
    // server.post(`${apiPrefix}/admin/donors/send_year_end`, (schema, request) => {
    //   return new Response(200, {}, donorData['admin/donors/send_year_end'])
    // })
  
    // server.post(`${apiPrefix}/admin/donors/activate`, (schema, request) => {
    //   return new Response(200, {}, donorData['admin/donors/activate'])
    // })
  
    // server.put(`${apiPrefix}/admin/commitments/{data.commitment_id}`, (schema, request) => {
    //   return new Response(200, {}, donorData['admin/commitments/*'])
    // })
  
    // server.patch(`${apiPrefix}/admin/commitments`, (schema, request) => {
    //   return new Response(200, {}, donorData['admin/commitments'])
    // })
  
    // server.delete(`${apiPrefix}/admin/delete/donors`, (schema, request) => {
    //   return new Response(200, {}, donorData['admin/delete/donors'])
    // })
  
    server.get(`${apiPrefix}/admin/donors`, (schema, request) => {
      return new Response(200, {}, all_donors)
    })
  }