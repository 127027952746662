
  import { Server, Response } from 'miragejs'
  import { salesDashboardData } from '../data/salesData'
  
  export default function salesFakeApi(server: Server, apiPrefix: string) {
  
    server.post(`${apiPrefix}/sales/dashboard`, (schema, request) => {
      // TODO: Handle dynamic parameters from request.params
      return new Response(200, {}, salesDashboardData)
    })
  
    server.post(`${apiPrefix}/sales/products`, (schema, request) => {
      // TODO: Handle dynamic parameters from request.params
      return new Response(200, {}, salesDashboardData)
    })
  
    server.delete(`${apiPrefix}/sales/products/delete`, (schema, request) => {
      // TODO: Handle dynamic parameters from request.params
      return new Response(200, {}, salesDashboardData)
    })
  
    server.delete(`${apiPrefix}/admin/auto_emails/emailset/{data.emailset_id}`, (schema, request) => {
      // TODO: Handle dynamic parameters from request.params
      return new Response(200, {}, salesDashboardData)
    })
  
    server.get(`${apiPrefix}/sales/product`, (schema, request) => {
      // TODO: Handle dynamic parameters from request.params
      return new Response(200, {}, salesDashboardData)
    })
  
    server.get(`${apiPrefix}/admin/auto_emails/emailset/{data.emailset_id}`, (schema, request) => {
      // TODO: Handle dynamic parameters from request.params
      return new Response(200, {}, salesDashboardData)
    })
  
    server.put(`${apiPrefix}/sales/products/update`, (schema, request) => {
      // TODO: Handle dynamic parameters from request.params
      return new Response(200, {}, salesDashboardData)
    })
  
    server.put(`${apiPrefix}/admin/auto_emails/emailset`, (schema, request) => {
      // TODO: Handle dynamic parameters from request.params
      return new Response(200, {}, salesDashboardData)
    })
  
    server.post(`${apiPrefix}/sales/products/create`, (schema, request) => {
      // TODO: Handle dynamic parameters from request.params
      return new Response(200, {}, salesDashboardData)
    })
  
    server.get(`${apiPrefix}/sales/orders`, (schema, request) => {
      // TODO: Handle dynamic parameters from request.params
      return new Response(200, {}, salesDashboardData)
    })
  
    server.delete(`${apiPrefix}/sales/orders/delete`, (schema, request) => {
      // TODO: Handle dynamic parameters from request.params
      return new Response(200, {}, salesDashboardData)
    })
  
    server.get(`${apiPrefix}/sales/orders-details`, (schema, request) => {
      // TODO: Handle dynamic parameters from request.params
      return new Response(200, {}, salesDashboardData)
    })
  }