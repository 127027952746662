
  import { Server, Response } from 'miragejs'
  
  export default function sponsorshipFakeApi(server: Server, apiPrefix: string) {
  
    server.get(`${apiPrefix}/admin/sponsorships/program/:program_id`, (schema, request) => {
      const query = request.queryParams.query?.toLowerCase()
      const pageIndex = parseInt(request.queryParams.pageIndex) || 0
      const pageSize = parseInt(request.queryParams.pageSize) || 10
      
      const allSponsorships = schema.db.sponsorships
      const totalRecords = allSponsorships.length

      if (query) {
        const filteredSponsorships = allSponsorships.filter(sponsorship => 
          sponsorship.en_child_name.toLowerCase().includes(query) ||
          sponsorship.dn_donor_name.toLowerCase().includes(query)
        )

        return new Response(200, {}, {
          data: filteredSponsorships,
          length: filteredSponsorships.length,
          filter: "false",
          program_id: "all",
          pageIndex: pageIndex,
          pageSize: pageSize,
          totalPages: Math.ceil(totalRecords / pageSize)
        })
      }
      
      let startIndex = pageIndex === 1 ? 0 : (pageIndex * pageSize)/pageIndex
      let endIndex = Math.min(startIndex + pageSize, totalRecords)

      if (pageIndex === 3) {
        startIndex = 21
        endIndex = totalRecords
      }
      
      const paginatedSponsorships = allSponsorships.slice(startIndex, endIndex)
      
      return new Response(200, {}, {
        data: paginatedSponsorships,
        length: totalRecords,
        filter: "false",
        program_id: "all",
        pageIndex: pageIndex,
        pageSize: pageSize,
        totalPages: Math.ceil(totalRecords / pageSize)
      })
    })
  
    server.post(`${apiPrefix}/admin/sponsorships/:program_id/headers`, (schema, request) => {
      // TODO: Handle dynamic parameters from request.params
      return new Response(200, {}, schema.db.sponsorships_headers[0])
    })
  
    server.get(`${apiPrefix}/admin/fields/options/:program_id}/:type`, (schema, request) => {
      // TODO: Handle dynamic parameters from request.params
      return new Response(200, {}, schema.db.sponsorships_fields[0])
    })
  
    // server.post(`${apiPrefix}/admin/fields/options/{data.program_id}/{data.type}`, (schema, request) => {
    //   // TODO: Handle dynamic parameters from request.params
    //   return new Response(200, {}, sponsorshipData['admin/fields/options/*/*'])
    // })
  
    server.get(`${apiPrefix}/admin/fields/options/:program_id/all`, (schema, request) => {
      // TODO: Handle dynamic parameters from request.params
      return new Response(200, {}, schema.db.sponsorships_fields[0])
    })
  
    server.get(`${apiPrefix}/admin/sponsorships/statistics`, (schema, request) => {
      // TODO: Handle dynamic parameters from request.params
      return new Response(200, {}, schema.db.sponsorships_statistics[0])
    })
  
    // server.get(`${apiPrefix}/admin/sponsorships/new`, (schema, request) => {
    //   // TODO: Handle dynamic parameters from request.params
    //   return new Response(200, {}, sponsorshipData['admin/sponsorships/new'])
    // })
  
    // server.get(`${apiPrefix}/admin/fields/options/sponsorships`, (schema, request) => {
    //   // TODO: Handle dynamic parameters from request.params
    //   return new Response(200, {}, sponsorshipData['admin/fields/options/sponsorships'])
    // })
  }